import React, { useEffect, useState } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
import Spinner from "../Auth/Spinner";
import { isMobile } from "react-device-detect";
import element3 from "../../assets/PNG/ELEMNTS-01.png";

export default function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const language = sessionStorage.getItem("lang") || "en";

  const handleChangePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    axios
      .patch("/users/changePassword", body, {
        headers: {
          Authorization: ` ${token}`, // set Authorization header with token value
        },
      })
      .then((res) => {
        toast.success("Password changed successfully.", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
        setLoading(false);
        setOldPassword("");
        setNewPassword("");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
        // console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      {isMobile ? (
        <Form className="form">
          <Row className="mb-3">
            <Form.Group className controlId="formGridEmail">
              <Form.Label>{language === "ar" ? "كلمة المرور القديمة":"Old Password"}</Form.Label>
              <Form.Control
                className="input mb-3"
                type="password"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail"></Form.Group>

            <Form.Group controlId="formGridEmail">
              <Form.Label>{language === "ar" ?"كلمة المرور الجديدة" :"New Password"}</Form.Label>
              <Form.Control
                className="input"
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Form.Group>
          </Row>
          <div className="cart-shiping-update-wrapper">
            <div className="cart-shiping-update">
              <button
                className="custom-btn mb-5"
                onClick={(e) => {
                  handleChangePassword(e);
                }}
                disabled={oldPassword == "" || newPassword == ""}
              >
                <span> {loading ? <Spinner /> : language === "ar" ?"تأكيد":"Submit Changes"}</span>
              </button>
            </div>
          </div>
        </Form>
      ) : (
        <Form className="form">
          <Row className="mb-3">
            <img src={element3} style={{ width: "80px" }} alt="" />
            <Form.Group controlId="formGridEmail">
            <Form.Label>{language === "ar" ? "كلمة المرور القديمة":"Old Password"}</Form.Label>

              <Form.Control
                className="input"
                type="password"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="my-4" controlId="formGridEmail"></Form.Group>

            <Form.Group controlId="formGridEmail">
            <Form.Label>{language === "ar" ?"كلمة المرور الجديدة" :"New Password"}</Form.Label>

              <Form.Control
                className="input"
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Form.Group>
          </Row>
          <div className="cart-shiping-update-wrapper">
            <div className="cart-shiping-update">
              <button
                className="custom-btn mt-3"
                onClick={(e) => {
                  handleChangePassword(e);
                }}
                disabled={oldPassword == "" || newPassword == ""}
              >
                <span> {loading ? <Spinner /> :language === "ar" ?"تأكيد": "Submit Changes"}</span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
