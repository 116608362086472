import { useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../Auth/Spinner";
import axios from "../../axios/axios";

const Subscribe = ({ closeFn }) => {
  const language = sessionStorage.getItem("lang") || "en";
  const loggedIn = sessionStorage.getItem("loggedIn") || false;
  const token = sessionStorage.getItem("token");
  const userEmail = sessionStorage.getItem("email") || "";
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "/subscription/subscribe",
        {
          email,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("subscribed", true);
        sessionStorage.setItem("closed", true);
        toast.success(res.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
        closeFn();
      })
      .catch((err) =>
        toast.error(err.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="blur" onClick={closeFn}></div>
      <div className="centered-window bg-white shadow-lg">
        <h3 className="text-center heading">
          {language === "ar" ? "5% خصم" : "5% DISCOUNT"}
        </h3>
        <h4
          style={{
            fontFamily: "BrownMedium",
            backgroundSize: "300px",
            fontSize: "20px",
            fontWeight: "500",
            textAlign: "center",
            paddingTop: "1rem",
          }}
        >
                    {language === "ar" ? "استمتع بهديتنا الصغيرة عند الاشتراك في نشرتنا الإخبارية" : "Enjoy our Little gift when you sign up to our newsletter"}

          
        </h4>
        <form className="form" onSubmit={onSubmit}>
          {loggedIn ? (
            <>
              <p className="m-0 text-center text-dark">using this email</p>
              <p className="text-center text-secondary">{userEmail}</p>
            </>
          ) : (
            <input
              placeholder={language === "ar" ? "البريد الالكتروني" : "E-mail"}
              id="email"
              name="email"
              type="email"
              className="input"
              required=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          <button
            type="submit"
            className="custom-btn mt-3"
            style={{ width: "100%" , fontFamily:"BrownMedium"}}
          >
            <span style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {loading ? <Spinner /> : language === "ar" ? "الاشتراك الان" : "SUBSCRIBE NOW"}
            </span>
          </button>
        </form>
      </div>
    </>
  );
};

export default Subscribe;
