import React from "react";
import story from "../../assets/about/story.jpg";
import "../../styles/Mission/mission.css";
import { isMobile } from "react-device-detect";
import element3 from "../../assets/PNG/ELEMNTS-18.png";

export default function Story() {
  const language = sessionStorage.getItem("lang") || "en";

  return (
    <>
      <div className="container mt-5 pt-md-5 pt-0 overflow-hidden">
        <div
          className="row align-items-center overflow-hidden"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <div
            className={
              language === "ar" ? "col-md-9 me-md-9" : "col-md-7 me-md-5"
            }
          >
            <h4 style={{ fontFamily: "Holydayfree" }}>
              <img src={element3} style={{ width: "50px" }} alt="" />
              {language === "ar" ? "قصتنا" : "Our Story"}
            </h4>
            <p className="fs-5" style={{ fontFamily: "BrownMedium" }}>
              {language === "ar"
                ? "في O Curls ، بدأت رحلتنا بمهمة شخصية: تحويل صراع الشعر المجعد إلى احتفال بالجمال الطبيعي. أسستها علا رشدي ، وهي مدافعة شغوفة عن تجعيد الشعر الطبيعي ، ونحن ندافع عن حب الذات والتمكين والجمال الفريد لكل تجعيد. انضم إلى مجتمعنا واحتضن رحلة اكتشاف الذات والقبول ."
                : "At O Curls, our journey began with a personal mission: to turn the curly hair struggle into a celebration of natural beauty. Founded by Ola Roshdy, a passionate advocate for natural curls, we stand for self-love, empowerment, and the unique beauty of each curl. Join our community and embrace the journey of self-discovery and acceptance."}
            </p>
          </div>
          <div
            className={
              language === "ar"
                ? "col-md-3  overflow-hidden"
                : "col-md-3 ms-md-5 overflow-hidden"
            }
          >
            {isMobile ? (
              <img
                src={story}
                className="w-100"
                height={380}
                alt="story"
                style={{ borderRadius: "10px" }}
              />
            ) : (
              <img
                src={story}
                width={400}
                alt="story"
                style={{ borderRadius: "10px", width: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
