import React from "react";
import curl from "../../assets/about/COMMUNICATION-02-03.jpg";
import curl2 from "../../assets/about/COMMUNICATION-02-04.jpg";
import "../../styles/Mission/mission.css";
import { isMobile } from "react-device-detect";
import element1 from "../../assets/PNG/ELEMNTS-04.png";
import element2 from "../../assets/PNG/ELEMNTS-05.png";
export default function Mission() {
  
  const language = sessionStorage.getItem("lang") || "en";
  return (
    <>
    <div 
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
          >
      {isMobile ? (
        <div className="row align-items-center px-3 pt-5 "
       
        >
          <div className="col-md-4" 
         
          >
            <div>
              <img
                src={curl2}
                className="w-100"
                style={{ borderRadius: "10px", height: "60vh" }}
                height={350}
              />
            </div>
          </div>
          <div className="col-md-7 ms-md-5 mt-md-0 mt-3" 
         
          >
            <div className="row align-items-center">
              <h4 className="fw-bold" style={{ fontFamily: "Holydayfree" }}>
                <img src={element1} style={{ width: "45px" }} alt="" />
                {language === "ar"?"مهمتنا": "Mission"}
              </h4>
              <p className="fs-5">
              {language === "ar"? "تمكين الأفراد من احتضان قوام الضفيرة الفريد بثقة وسهولة. الاحتفال بالجمال الطبيعي ، تجعيد نطاط واحد في كل مرة.":"Empowering individuals to embrace their unique curl textures with confidence and ease. Celebrating natural beauty, one bouncy curl at a time."}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5 " style={{ fontFamily: "BrownMedium" }}>
          <div className="row align-items-center">
            <div className="col-md-4">
              <div>
                <img
                  src={curl2}
                  className="w-100"
                  style={{ borderRadius: "10px" }}
                  // height={350}
                />
              </div>
            </div>
            <div className="col-md-7 ms-md-5 mt-md-0 mt-3">
              <div className="row align-items-center">
                <h4 className="fw-bold" style={{ fontFamily: "Holydayfree" }}>
                  <img src={element1} style={{ width: "45px" }} alt="" />
                  {language === "ar"?"مهمتنا": "Mission"}

                </h4>
                <p className="fs-5">
                {language === "ar"? "تمكين الأفراد من احتضان قوام الضفيرة الفريد بثقة وسهولة. الاحتفال بالجمال الطبيعي ، تجعيد نطاط واحد في كل مرة.":"Empowering individuals to embrace their unique curl textures with confidence and ease. Celebrating natural beauty, one bouncy curl at a time."}

                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile ? (
        <div className="row align-items-center flex-column flex-column-reverse px-3 ">
          <div className="col-md-7 ms-md-5 mt-md-0 mt-3 ">
            <div className="row">
              <h4 className="fw-bold" style={{ fontFamily: "Holydayfree" }}>
                <img src={element2} style={{ width: "45px" }} alt="" />
                {language === "ar"?"رؤيتنا" :   "Vision"}
              </h4>
              <p className="fs-5 w-100 mb-0 pb-0">
              {language === "ar"? "أن نكون منارة الأمل والإلهام للأفراد ذوي الشعر المجعد في جميع أنحاء العالم ، وتوفير حلول متميزة ومستدامة لجميع تحديات التجعيد.":"To be the beacon of hope and inspiration for curly-haired individuals worldwide, providing premium, sustainable solutions for all curl challenges."}
              </p>
            </div>
          </div>
          <div className="col-md-4 ms-md-5">
            <div>
              <img
                src={curl}
                className="w-100 mb-0 pb-0"
                style={{ borderRadius: "10px", height: "47vh" }}
                // height={350}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container mt-md-5">
          <div className="row align-items-center">
            <div className="col-md-7 ms-md-5 mt-md-0 mt-3">
              <div className="row">
                <h4 className="fw-bold" style={{ fontFamily: "Holydayfree" }}>
                  <img src={element2} style={{ width: "45px" }} alt="" />
                  {language === "ar"?"رؤيتنا" :   "Vision"}

                </h4>
                <p className="fs-5 fw-bold">
                {language === "ar"? "أن نكون منارة الأمل والإلهام للأفراد ذوي الشعر المجعد في جميع أنحاء العالم ، وتوفير حلول متميزة ومستدامة لجميع تحديات التجعيد.":"To be the beacon of hope and inspiration for curly-haired individuals worldwide, providing premium, sustainable solutions for all curl challenges."}

                </p>
              </div>
            </div>
            <div className="col-md-4 ms-md-5 ">
              <div>
                <img
                  src={curl}
                  className="w-100 "
                  style={{ borderRadius: "10px" }}
                  // height={350}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
}
