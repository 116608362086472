import React, { useState } from "react";
import "../../styles/Auth/auth.css";
import axios from "../../axios/axios";
import { toast } from "react-toastify";
import Spinner from "./Spinner";
export default function RegisterForm() {
  const language = sessionStorage.getItem("lang") || "en";
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState({
    confirmPassword: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    password: "",
    phone: "",
    lastName: "",
    file: null,
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log(file)
    setFormData((prevFormData) => ({
      ...prevFormData,
      file: file,
    }));
  };

 
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "password" && value !== confirmPassword.confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };
  const handleChangeConfirm = (e) => {
    const { name, value } = e.target;
    setConfirmPassword({ [name]: value });
    if (formData.password !== value) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.password !== confirmPassword.confirmPassword) {
      setPasswordError(  language === "ar" ?"كلمات المرور غير متطابقة":"Passwords do not match");
      setLoading(false);
      return;
    } else {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key !== "file" || (key === "file" && formData.file)) {
          formDataToSend.append(key, formData[key]);
        }
      });
      

      try {
        const response = await axios.post("/users/register", formDataToSend);

        toast.success("Welcome to O'CURLS, go to login.", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
window.location.replace("/")
        setLoading(false);
        setPasswordError("");
        // window.location.replace("/login");
      } catch (error) {
        toast.error(error.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });

        setLoading(false);
        console.error("Registration failed:", error.message);
      }
    }
  };
  return (
    <>
      <div
        className="container pt-md-0 pt-2 pb-md-0 pb-3"
        style={{ paddingTop: "2rem", paddingBottom: "5rem" }}
      >
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col containerRegister">
            <div className="heading"> {language === "ar" ?"التسجيل":"Sign Up"}</div>
            <form className="form" onSubmit={handleSubmit}     {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="input-div">
                  <input
                    className="inputImage"
                    name="file"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    fill="none"
                    stroke="currentColor"
                    className="icon"
                  >
                    <polyline points="16 16 12 12 8 16"></polyline>
                    <line y2="21" x2="12" y1="12" x1="12"></line>
                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                    <polyline points="16 16 12 12 8 16"></polyline>
                  </svg>
                </div>
              </div>
              <input
                placeholder=   {language === "ar" ?"الاسم الأول":"First Name"}
                id="firstName"
                name="firstName"
                type="text"
                className="input"
                required=""
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                placeholder=   {language === "ar" ?"الاسم الأخير":"Last Name"}

                id="lastName"
                name="lastName"
                type="text"
                className="input"
                required=""
                value={formData.lastName}
                onChange={handleChange}
              />
              <input
                placeholder=   {language === "ar" ?"رقم التليفون":"Phone"}

                id="phone"
                name="phone"
                type="number"
                className="input"
                required=""
                value={formData.phone}
                onChange={handleChange}
              />
              <input
                placeholder=   {language === "ar" ?"البريد الالكتروني":"E-mail"}

                id="email"
                name="email"
                type="email"
                className="input"
                required=""
                value={formData.email}
                onChange={handleChange}
              />
              <input
                placeholder=   {language === "ar" ?"كلمة السر":"Password"}

                
                id="password"
                name="password"
                type="password"
                className="input"
                required=""
                value={formData.password}
                onChange={handleChange}
              />
              <input
                placeholder=   {language === "ar" ?"تأكيد كلمة السر":"Confirm Password"}

                
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="input"
                required=""
                value={confirmPassword.confirmPassword}
                onChange={handleChangeConfirm}
              />
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
              <button
                type="submit"
                className="custom-btn mt-3"
                style={{ width: "100%" }}
              >
                <span>{loading ? <Spinner /> :  language === "ar" ?"تسجيل الدخول":"Sign up"}</span>
              </button>
              {/* <input value="Sign In"  className="login-button" /> */}
            </form>
            <div className="social-account-container">
              <span className="title">{language === "ar" ?"هل لديك حساب بالفعل؟":"Already have an account?"}</span>
              <span className="agreement">
                <a href="/login">{language === "ar"?"تسجيل الدخول الآن":"Login now"}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
