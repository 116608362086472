import React, { useEffect, useState } from "react";
import discount from "../../assets/discount/promotional Banner.png";
import "../../styles/discount/discount.css";
import axios from "../../axios/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function Discount() {
  const language = sessionStorage.getItem("lang") || "en";

  const [banners, setBanners] = useState([]);
  useEffect(() => {
    axios
      .get("promotionalbanners")
      .then((response) => {
        // console.log(response);
        setBanners(response.data);
      })
      .catch((error) => {});
  }, []);
  // console.log(banners);
  return (
    <div className="container p-5 pt-0 mt-0 pe-md-3 overflow-hidden">
      <div className="row mt-0 pt-0">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {banners.map((one, index) => (
            <SwiperSlide key={index}>
              <div
                className="col-md-12 col-sm-12 mt-0 pt-0 discount "
              
              >
                {isMobile ? (
                  <img
                    src={one.image}
                    alt={`Banner ${index}`}
                    // className=" ps-2 me-2"
                    style={{
                      minHeight: "185px",
                      maxHeight: "50px",
                      width: "490px",
                    }}
                  />
                ) : (
                  <img
                    src={one.image}
                    alt={`Banner ${index}`}
                    style={{ height: "58vh", width: "100%" }}
                  />
                )}

                {/* Ensure alt text */}
                {isMobile ?
               "":
               <div
               className={
                 language === "ar" ? "discountContent-ar" : "discountContent"
               }
             >
               <Link to={"/shop"}>
                 {" "}
                 <button className="custom-btn mt-3">
                   <span>
                     {language === "ar"
                       ? "ابدأ التسوق الان"
                       : "Start shopping now"}
                   </span>
                 </button>
               </Link>
             </div>
               }
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
