import React from "react";
import header from "../../assets/header/contact.png";
export default function ContactHeader() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-1"></div>
            <div className="col-md-3">
<div className="row" style={{alignItems:'center',height:'100%'}} >
  <div>
              <h5>Home <span> - </span> Contact</h5> 
              <h2>Contact</h2>
            </div></div>
            </div> */}
          <div className="col-md-12 mt-5 pt-5" style={{ paddingRight: "10px" }}>
            <img src={header} alt="" className="headerBg" />
          </div>
        </div>
      </div>
    </>
  );
}
