import React from "react";
import ContactForm from "./ContactForm";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/header/topHeader.css";
import element1 from "../../assets/PNG/ELEMNTS-15.png";
import element2 from "../../assets/PNG/ELEMNTS-01.png";
import element3 from "../../assets/PNG/ELEMNTS-17.png";

export default function ContactMain() {
  const language = sessionStorage.getItem("lang") || "en";

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <div
          className="row"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <div className="d-flex justify-content-between w-100">
            <img src={element1} style={{ width: "90px" }} alt="" />

            <img src={element2} style={{ width: "90px" }} alt="" />
          </div>
          <div className="col">
            <ContactForm />
          </div>

          <div className="col contactContent d-flex flex-column">
            <div>
              <h5 style={{ fontFamily: "Holydayfree" }}>
                <img src={element3} style={{ width: "45px" }} alt="" />
                {language === "ar"?"تواصل معنا":" Contact"}
              </h5>
              <p className="text-muted">
                {language === "ar"
                  ? "ابق على اتصال مع O Curls نحن نحب أن نسمع منك! للأسئلة أو التعليقات أو لمجرد مشاركة رحلة الضفيرة الخاصة بك ، تواصل معنا من خلال أي من القنوات التالية. تلهمنا تجعيد الشعر وقصصك وملاحظاتك لمواصلة الابتكار والتحسن."
                  : "Stay Connected with O Curls We love to hear from you! For questions, feedback, or just to share your curl journey, get in touch with us through any of the following channels. Your curls, your stories, and your feedback inspire us to keep innovating and improving."}
              </p>{" "}
              <p>
                <i className="fa-solid fa-phone iconContact"></i>{" "}
                <span>
                  {" "}
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    href="tel:+201009910288"
                  >
                    01009910288
                  </a>
                </span>
              </p>
              <p>
                <i className="fa-regular fa-envelope iconContact"></i>{" "}
                <span>
                  {" "}
                  <a
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    href="mailto:Ocurls425@gmail.com"
                  >
                    Ocurls425@gmail.com
                  </a>
                </span>
              </p>
              <div className="socialMediasContact d-flex justify-content-start gap-4 ">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=61556119991952&mibextid=2JQ9oc"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ color: "#e23b26" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/o.curls_?igsh=eXJpY2Myd2p4dTMx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "#e23b26" }}
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@o.curls?_t=8jn4xHBAT2M&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    style={{ color: "#e23b26" }}
                  />
                </a>
                <a
                  href="http://www.youtube.com/@OCurls-lu6re"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "#e23b26" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3455.686935324928!2d31.1391111!3d29.9884259!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2seg!4v1709475837001!5m2!1sen!2seg"
              width="100%"
              height="600"
              allowfullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div> */}
    </>
  );
}
