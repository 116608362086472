import React, { useRef, useEffect, useState } from "react";
// import Swiper from "swiper"
import "../../styles/featuredProduct/featuredProduct.css";
import "swiper/css"; // Import Swiper CSS
import bannerImg from "../../assets/banner/banner.jpg";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import "../../styles/shop/shop.css";
import Slider from "@mui/material/Slider";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import { useCart } from "../../CartContext";
import { useWishlist } from "../../WishlistContext";

import Spinner from "../Auth/Spinner";
import { isMobile } from "react-device-detect";

export default function WikshlistShopStyle({ wishlistData }) {
  const { updateCartCount } = useCart();
  const { updateWishlistCount } = useWishlist();
  // console.log(wishlistData)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const language = sessionStorage.getItem("lang") || "en";

  const pp = ["1", "2", "3", "4", "5", "6"];
  const hairTypes = ["2A", "3A", "4A", "2B", "3B", "4B", "2C", "3C", "4C"];
  const swiperRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState(wishlistData);
  const [price, setPrice] = useState({});
  const [value, setValue] = useState([0, 20000]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedHairTypes, setSelectedHairTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [activeVariant, setActiveVariant] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [priceValue, setPriceValue] = useState([]);
  const [selectedWeights, setSelectedWeights] = useState([]);
  const [product, setProduct] = useState();
  const [reload, setReload] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const perPage = 9;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  }
  useEffect(() => {
    if (pp.length > 0 && swiperRef.current) {
      new Swiper(swiperRef.current, {
        slidesPerView: 4,
        // Add any other Swiper options you need
      });
    }
  }, [pp]);
  //  console.log(price)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPriceValue(newValue);
  };
  const valuetext = (value) => {
    return `$${Math.floor(value)}`;
  };
  const handleWeightChange = (weight) => {
    // console.log(weight);
    if (selectedWeights.includes(weight)) {
      setSelectedWeights(selectedWeights.filter((w) => w !== weight));
    } else {
      setSelectedWeights([...selectedWeights, weight]);
    }
  };

  const fetchWishlistData = async () => {
    try {
      const response = await axios.get("/users/wishlist", {
        headers: { Authorization: sessionStorage.getItem("token") },
      });
      setProducts(wishlistData);
      // console.log(response.data.wishlist);

      setLoading(false);
    } catch (error) {
      // setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    // Update cartData when data prop changes
    setProducts(wishlistData);
  }, [wishlistData]);

  useEffect(() => {
    fetchWishlistData();
  }, []);

  const token = sessionStorage.getItem("token");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleRemoveFromWishlist = async (productId, variantId) => {
    try {
      await axios.delete(
        `/users/removeFromWishlist/${productId}/${variantId}`,
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );

      toast.success("Product removed from wishlist successfully", {
        hideProgressBar: true,
        autoClose: 3000,
      });
      updateWishlistCount((previousCount) => previousCount - 1);
      // Remove the product from the local state
      const updatedProducts = products.filter(
        (item) =>
          item.product._id !== productId ||
          item.product.variant._id !== variantId
      );
      setProducts(updatedProducts);
      // console.log("Product removed from wishlist successfully");
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
      });
      console.error("Error removing product from wishlist:", error);
    }
  };

  const handleAddtoCart = (check, productId, variantId) => {
    if (!check) {
      axios
        .post(
          "users/addToCart",
          {
            productId: productId,
            variantId: variantId,
            quantity: quantity,
          },
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) + 1
          );
          setQuantity(1);
          setReload(!reload);
          updateCartCount((previousCount) => previousCount + 1);

          fetchWishlistData();
          toast.success("product added to your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    } else {
      axios
        .delete("users/removeFromCart/" + productId + "/" + variantId, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) - 1
          );
          setReload(!reload);
          updateCartCount((previousCount) => previousCount - 1);

          fetchWishlistData();
          toast.success("product removed from your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    }
  };
  const handleClearWishlist = async () => {
    try {
      await axios.delete("/users/emptyWishlist", {
        headers: { Authorization: sessionStorage.getItem("token") },
      });
      // console.log("Wishlist cleared successfully");
      fetchWishlistData();

      toast.success("Wishlist cleared successfully", {
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);
      // Assuming you have a function to fetch updated wishlist data, you can call it here
      // fetchWishlistData();
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
      });
      setLoading(false);

      console.error("Error clearing wishlist:", error);
    }
  };

  return (
    <>
      <div
        className="container "
        style={{ marginTop: "3rem" }}
        {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      >
        <div className="row">
          <div
            className="col-md-9 overflow-hidden mx-auto"
            style={{ position: "relative" }}
          >
            <div
              className="row"
              style={{ marginBottom: "2rem", justifyContent: "space-between" }}
            ></div>
            {loading2 ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  height: "20vh",
                  alignItems: "end",
                }}
              >
                <div className="typing-indicator">
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                </div>
              </div>
            ) : (
              <div className="row ">
                {products.length > 0 &&
                  products.map((one) => (
                    <div className="col-md-4" key={one._id}>
                      <div className="swiper-slide">
                        <div
                          className="item"
                          style={{
                            marginBottom: "4rem",
                            border: "1px solid #e23b26",
                          }}
                        >
                          <div className="image">
                            <img
                              src={one?.images[0]}
                              alt={`Banner `}
                              style={{ maxHeight: "440px", minHeight: "440px" }}
                            />
                            {/* <Swiper
                              // spaceBetween={30}
                              slidesPerView={1}
                              // onSlideChange={() => console.log("slide change")}
                              // onSwiper={(swiper) => console.log(swiper)}
                            > */}{" "}
                            {/* {one.product?.images.map((image, index) => ( */}
                            {/* <SwiperSlide>
                              
                                
                                </SwiperSlide> */}
                            {/* // ))}{" "} */}
                            {/* </Swiper> */}
                            {one.variant?.stock > 0 &&
                            one.variant?.isSale === true ? (
                              <div className="discountShop">
                                {/* {console.log(one.variants)} */}
                                {one.variant?.salePercentage}% <br /> off
                              </div>
                            ) : (
                              ""
                            )}
                            {/* // variants.map((variant, index) => */}
                            {one.variant.isAvailable ? (
                              <div className="cartButtonProduct">
                                <button
                                  className="cart"
                                  onClick={() => {
                                    if (sessionStorage.getItem("token")) {
                                      const updatedVariant = {
                                        ...activeVariant,
                                        inCart: !activeVariant.inCart,
                                      };

                                      // Call handleAddToCart if inCart is true

                                      setActiveVariant({
                                        ...activeVariant,
                                        inCart: !activeVariant.inCart,
                                      });
                                      // Return the updated variant

                                      // console.log(updatedVariant.inCart);

                                      handleAddtoCart(
                                        updatedVariant.inCart,
                                        one._id,
                                        one.variant._id
                                      );
                                    } else {
                                      window.location.replace("/login");
                                    }
                                  }}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    style={{ fontSize: "1.3rem" }}
                                    className="icon"
                                    icon={faBasketShopping}
                                    size="xl"
                                  />
                                  {language === "ar" ? (
                                    <span>
                                      {" "}
                                      {activeVariant.inCart
                                        ? "إزالة من الحقيبة"
                                        : "أضف الى الحقيبة"}
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      {activeVariant.inCart
                                        ? "REMOVE FROM BAG"
                                        : "ADD TO BAG"}
                                    </span>
                                  )}
                                </button>
                                <button className="heart heart-container">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={activeVariant.isFav}
                                    onChange={(e) => {
                                      if (sessionStorage.getItem("token")) {
                                        handleRemoveFromWishlist(
                                          one._id,
                                          one.variant._id
                                        );
                                      } else {
                                        window.location.replace("/login");
                                      }
                                    }}
                                    id="Give-It-An-Id"
                                  />

                                  <i className="fa-solid fa-heart"></i>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="cartButtonProduct"
                                style={{ marginTop: "1rem" }}
                              >
                                <button
                                  className="cart"
                                  style={{ cursor: "default" }}
                                >
                                  <span>
                                    {" "}
                                    {language === "ar"
                                      ? "إنتهى من المخزن"
                                      : "OUT OF STOCK"}
                                  </span>
                                </button>
                                <button className="heart heart-container">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={activeVariant.isFav}
                                    onChange={(e) => {
                                      if (sessionStorage.getItem("token")) {
                                        handleRemoveFromWishlist(
                                          one._id,
                                          one.variant._id
                                        );
                                      } else {
                                        window.location.replace("/login");
                                      }
                                    }}
                                    id="Give-It-An-Id"
                                  />

                                  <i className="fa-solid fa-heart"></i>
                                </button>
                              </div>
                            )}
                          </div>

                          <Link
                            to={`/singleProduct/${one._id}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <div className="content p-3">
                              <div className="subCategory">
                                {language === "ar"
                                  ? one?.subCategory?.category?.nameAr
                                  : one?.subCategory?.category?.name}
                              </div>
                              <div
                                className="title mb-3"
                                style={{
                                  height: "4vh",
                                  fontFamily: "BrownMedium",
                                }}
                              >
                                {language === "ar" ? one?.nameAr : one?.name}
                              </div>

                              {/* {one.variants.map((variant, index) => {
    let lowestPrice = Infinity;
    if (variant.price < lowestPrice) {
        lowestPrice = variant.price;
    }
    return ( */}
                              <React.Fragment>
                                <div className="price">
                                  {one.variant?.stock === 0 ? (
                                    <>
                                      <div className="discountShop">
                                        {language === "ar"
                                          ? "إنتهى من المخزن"
                                          : "OUT OF STOCK"}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </React.Fragment>
                              <React.Fragment>
                                <div className="price">
                                  {
                                    // one.minPrice === one.maxPrice ? (
                                    //   one.minPrice + " EGP"
                                    // ) :
                                    one.variant?.isSale === true ? (
                                      <>
                                        {one.variant?.price.toFixed(2)}
                                        {language === "ar" ? "ج م" : "EGP"}
                                        <span
                                          className="priceShop"
                                          style={{ marginLeft: "8px" }}
                                        >
                                          {one.variant?.price +
                                            one.variant?.saleValue}{" "}
                                          {language === "ar" ? "ج م" : "EGP"}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {one?.variant?.price.toFixed(2)}{" "}
                                        {language === "ar" ? "ج م" : "EGP"}
                                      </>
                                    )
                                  }
                                </div>
                              </React.Fragment>

                              {/* //     );
// })} */}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {products.length > 0 ? (
              <div
                className="mt-md-0 mt-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <button className="custom-btn" onClick={handleClearWishlist}>
                    <span>
                      {language === "ar"
                        ? "مسح قائمة الرغبات"
                        : " Clear Wishlist"}
                    </span>
                  </button>
                )}
                <button className="custom-btn">
                  <span>
                    {" "}
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontFamily: "BrownMedium",
                      }}
                      to="/shop"
                    >
                      {" "}
                      {language === "ar"
                        ? "الاستمرار بالتسوق"
                        : "Continue shopping"}
                    </Link>
                  </span>
                </button>
              </div>
            ) : (
              ""
            )}
            {/* <div
              className="col-12"
              style={{
                justifyContent: "center",
                display: "flex",
                position: "absolute",
                bottom: "0",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {currentPage !== 1 && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </a>
                    </li>
                  )}
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <li className="page-item" key={startPage + index}>
                        <a
                          className={`page-link ${
                            currentPage === startPage + index ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(startPage + index)}
                          disabled={currentPage === startPage + index}
                        >
                          {startPage + index}
                        </a>
                      </li>
                    )
                  )}
                  {currentPage !== NoOfPages && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${
                          currentPage === NoOfPages ? "disabled" : ""
                        }`}
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
