import React, { useState } from "react";
import "../../styles/header/topHeader.css";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
const TopHeader = () => {
  const initialLanguage = sessionStorage.getItem("lang") || "en";
  const [language, setLanguage] = useState(initialLanguage);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    sessionStorage.setItem("lang", lang); // Save selected language to session storage
    window.location.reload();
  };

  return (
    <div className="topHeader fixed-top">
      {isMobile ? (
        <div className="content container">
          <Row>
            <Col
              sm={6}
              md={6}
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <div className="socialMedias gap-4 ">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=61556119991952&mibextid=2JQ9oc"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://www.instagram.com/o.curls_?igsh=eXJpY2Myd2p4dTMx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.tiktok.com/@o.curls?_t=8jn4xHBAT2M&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
                <a
                  href="http://www.youtube.com/@OCurls-lu6re"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </div>
              <div style={{ textAlign: "end" }}>
                {language === "en" ? (
                  <button onClick={() => handleLanguageChange("ar")}>
                    العربية{" "}
                  </button>
                ) : (
                  <button onClick={() => handleLanguageChange("en")}>
                    English{" "}
                  </button>
                )}
                {/* {language !== "en" && (
        <div className="language-menu">
          <ul>
            <li onClick={() => handleLanguageChange("en")}>English</li>
            <li onClick={() => handleLanguageChange("ar")}>العربية</li>
          </ul>
        </div>
      )} */}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="content container">
          <Row>
            <Col sm={2} md={2}>
              <div className="socialMedias gap-4 ">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=61556119991952&mibextid=2JQ9oc"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://www.instagram.com/o.curls_?igsh=eXJpY2Myd2p4dTMx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.tiktok.com/@o.curls?_t=8jn4xHBAT2M&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
                <a
                  href="http://www.youtube.com/@OCurls-lu6re"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </div>
            </Col>
            <Col sm={8} md={8}></Col>
            <Col sm={2} md={2}>
              <span style={{cursor:"pointer"}}>
                {language === "en" ? (
                  <li onClick={() => handleLanguageChange("ar")}>العربية</li>
                ) : (
                  <li onClick={() => handleLanguageChange("en")}>English</li>
                )}
              </span>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
