import React, { useState } from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import Orders from "./Orders";
import Address from "./Address";
import { isMobile } from "react-device-detect";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export default function ProfileData() {
  const [active, setActive] = useState(1);
  const language = sessionStorage.getItem("lang") || "en";

  return (
    <>
      <div className="container mt-5 border-bottom-0"  {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}>
        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {isMobile ? (
            <div
              className="col-md-4 col-sm-12"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 1 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(1);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-user "
                      aria-hidden="true"
                      style={{ color: "red" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    بيانات الحساب
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Account details
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 2 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(2);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-lock "
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    تغيير كلمة المرور
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Change password
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 3 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(3);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-bag-shopping"
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    الطلبات
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Orders
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 4 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(4);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-location-dot "
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    العناوين
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    addresses
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={active === 5 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(5);
                    sessionStorage.clear();
                    sessionStorage.clear();
                    window.location.replace("/");
                    //   logOut();
                  }}
                >
                  {/* <i class="fa-solid fa-arrow-right-from-bracket"></i> */}
                  <div className="iconWrapper">
                    {" "}
                    <i
                      className="fa-solid fa-arrow-right-from-bracket"
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    تسجيل الخروج
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Logout
                  </span>}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="col-md-4 col-sm-12"
              style={{
                boxShadow:language === "ar" ?" -2px 0 2px 2px #888": "5px 0 3px -2px #888",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 1 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(1);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-user "
                      aria-hidden="true"
                      style={{ color: "red" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    بيانات الحساب
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Account details
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 2 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(2);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-lock "
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    تغيير كلمة المرور
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Change password
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 3 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(3);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-bag-shopping"
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    الطلبات
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Orders
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    marginBottom: "0.5rem",
                    alignItems: "center",
                  }}
                  className={active === 4 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(4);
                  }}
                >
                  <div className="iconWrapper">
                    <i
                      className="fa-solid fa-location-dot "
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    العناوين
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    addresses
                  </span>}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className={active === 5 ? "menitem active" : "menitem"}
                  onClick={() => {
                    setActive(5);
                    sessionStorage.clear();
                    sessionStorage.clear();
                    window.location.replace("/");
                    //   logOut();
                  }}
                >
                  {/* <i class="fa-solid fa-arrow-right-from-bracket"></i> */}
                  <div className="iconWrapper">
                    {" "}
                    <i
                      className="fa-solid fa-arrow-right-from-bracket"
                      aria-hidden="true"
                      style={{ color: "red", marginRight: "2%" }}
                    ></i>
                  </div>
                  {
                    language === "ar" ? <span className="forFont" style={{ marginRight: "2rem" }}>
                    {" "}
                    تسجيل الخروج
                  </span>
                  :
                  <span className="forFont" style={{ marginLeft: "2rem" }}>
                    {" "}
                    Logout
                  </span>}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-8 col-sm-12">
            {active === 1 && <EditProfile />}
            {active === 2 && <ChangePassword />}
            {active === 3 && <Orders />}
            {active === 4 && <Address />}
          </div>
        </div>
      </div>
    </>
  );
}
