import React, { useEffect, useState } from "react";
import tips from "../../assets/tips/caver Tips.png";
import axios from "../../axios/axios";
import { Link } from "react-router-dom";
import "../../styles/trends/trends.css";
import icon from "../../assets/PNG/ELEMNTS-14.png";
import { isMobile } from "react-device-detect";

export default function Trends() {
  const [blogs, setBlogs] = useState([]);
  const language = sessionStorage.getItem("lang") || "en";
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    setLoading(true);
    axios
      .get("blogs")
      .then((response) => {
        // console.log(response);
        setBlogs(response.data.blogs);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className="container pt-md-0 pt-5 pb-5 mt-5">
        <div
          className="row"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <div className="col">
            <h4 className="mainTitleTrend">
              <img src={icon} style={{ width: "40px" }} alt="" />
              {language === "ar" ? " المدونات" : "Blogs"}
            </h4>
          </div>
          <div className="col">
            <Link
              to="/blog"
              className="learnAbout"
              style={{
                fontWeight: "700",
                cursor: "pointer",
                fontFamily: "BrownMedium",
              }}
            >
              {language === "ar" ? " عرض الكل" : " View All"}
            </Link>
          </div>
        </div>
        {blogs.length > 0 ? (
          <div
            className="row pt-5 "
            {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
          >
            <div className="col-md-6 col-sm-12 p-0 pe-md-4">
              <div
                className=" cardTrends mb-3"
                style={{ overflow: "hidden", height: "71vh" }}
              >
                {" "}
                {blogs && blogs[0]?.image && (
                  <Link
                    to={`/singleBlog/${blogs[0]._id}`}
                    style={{ width: "100%" }}
                  >
                    <img
                      className="card-imageTrends"
                      src={blogs[0].image}
                      alt="Blog Image"
                      // style={{ height: "47vh" }}
                    />
                  </Link>
                )}
                <div className="headingTrends">
                  {" "}
                  <span className="headingTrendsTitle">
                    {" "}
                    {language === "ar"
                      ? blogs && blogs[0].titleArabic
                        ? blogs[0].titleArabic
                        : ""
                      : blogs && blogs[0].titleEnglish
                      ? blogs[0].titleEnglish
                      : ""}
                  </span>
                  <div className="authorTrends">
                    <span>
                      {language === "ar"
                        ? blogs && blogs[0].descriptionArabic
                          ? blogs[0].descriptionArabic
                          : ""
                        : blogs && blogs[0].descriptionEnglish
                        ? blogs[0].descriptionEnglish
                        : ""}
                    </span>
                    <br /> <br /> <span></span>{" "}
                    <div className="row">
                      <div className="col">
                        <span className="footerTrend">
                          {" "}
                          <i className="fa-solid fa-clock tipsIconTrends"></i>{" "}
                          {new Date(blogs[0].createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 ">
              <Link
                to={`/singleBlog/${blogs[1]._id}`}
                className="row pb-4 text-decoration-none "
              >
                <div className="row cardTrends">
                  <div className="col-md-5 ">
                    {blogs && blogs[1]?.image && (
                      <img
                        className="card-imageTrendsSmall"
                        src={blogs[1].image}
                        alt="Blog Image"
                        style={{ height: "22vh" }}
                      />
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="headingTrends">
                      {" "}
                      <span className="headingTrendsTitle">
                        {" "}
                        {language === "ar"
                          ? blogs
                            ? blogs[1].titleArabic
                            : ""
                          : blogs
                          ? blogs[1].titleEnglish
                          : ""}
                      </span>
                      <div className="authorTrends">
                        <div className="row">
                          <div className="col">
                            <span className="footerTrend">
                              {" "}
                              <i className="fa-solid fa-clock tipsIconTrends"></i>{" "}
                              {new Date(blogs[1].createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to={`/singleBlog/${blogs[2]._id}`}
                className="row pb-4 text-decoration-none"
              >
                <div className="row cardTrends">
                  <div className="col-md-5">
                    {blogs && blogs[2]?.image && (
                      <img
                        className="card-imageTrendsSmall"
                        src={blogs[2].image}
                        alt="Blog Image"
                        style={{ height: "20vh" }}
                      />
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="headingTrends">
                      {" "}
                      <span className="headingTrendsTitle">
                        {" "}
                        {language === "ar"
                          ? blogs
                            ? blogs[2].titleArabic
                            : ""
                          : blogs
                          ? blogs[2].titleEnglish
                          : ""}
                      </span>
                      <div className="authorTrends">
                        <div className="row">
                          <div className="col">
                            <span className="footerTrend">
                              {" "}
                              <i className="fa-solid fa-clock tipsIconTrends"></i>{" "}
                              {new Date(blogs[2].createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to={`/singleBlog/${blogs[3]._id}`}
                className="row pb-4 text-decoration-none"
              >
                <div className="row cardTrends">
                  <div className="col-md-5">
                    {blogs && blogs[3]?.image && (
                      <img
                        className="card-imageTrendsSmall"
                        src={blogs[3].image}
                        alt="Blog Image"
                        style={{ height: "20vh" }}
                      />
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="headingTrends">
                      {" "}
                      <span className="headingTrendsTitle">
                        {" "}
                        {language === "ar"
                          ? blogs
                            ? blogs[3].titleArabic
                            : ""
                          : blogs
                          ? blogs[3].titleEnglish
                          : ""}
                      </span>
                      <div className="authorTrends">
                        <div className="row">
                          <div className="col">
                            <span className="footerTrend">
                              {" "}
                              <i className="fa-solid fa-clock tipsIconTrends"></i>{" "}
                              {new Date(blogs[3].createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "20vh",
            alignItems: "end",
          }}
        >
          <div className="typing-indicator">
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
          </div>
        </div>
        )}
      </div>
    </>
  );
}
