import React from 'react'
import header from '../../assets/header/caver Pages.jpg'
export default function CartHeader() {
  return (
    <>
    <div className="container-fluid mt-5 pt-3">
        <div className="row">
            {/* <div className="col-md-1"></div>
            <div className="col-md-3">
<div className="row" style={{alignItems:'center',height:'100%'}} >
  <div>
              <h5>Home <span> - </span> Cart</h5> 
              <h2>Cart</h2>
           </div></div>
            </div> */}
            <div className="col-md-12 pe-2 mt-5" style={{paddingRight:'0'}}><img src={header} alt="" className="headerBg" /></div>

        </div>
    </div>
    </>
  )
}
