import React from 'react'
import header from "../../assets/header/caver Pages.jpg";
export default function PrivacyHeader() {
  return <>
           <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-12 mt-5 pt-5 pe-2" style={{ paddingRight: "0" }}>
            <img src={header}  className="headerBg"  />
          </div>
        </div>
      </div>
  
  </>
}
