import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "../../styles/counters/counters.css";
import followers from "../../assets/counters/followers.svg";
import orders from "../../assets/counters/orders.svg";
import reviews from "../../assets/counters/reviews.svg";

const Counters = () => {
  const [isVisible, setIsVisible] = useState(false);
  const language = sessionStorage.getItem("lang") || "en";

  const handleVisibilityChange = (isVisible) => {
    setIsVisible(isVisible);
  };

  return (
    <div
      className="container mt-md-0 mt-4 mb-0  overflow-hidden"
      style={{ marginTop: "3rem", marginBottom: "3rem" }}
    >
      <Row className="countersContainer">
        <Col md={4} xs={12} className="counter-item ">
          <div className="flex-center gap-4 ms-1 ps-1 pb-1">
            <img src={reviews} width={60} alt="Reviews" />
            <div className="flex-center">
              <span>
                <VisibilitySensor onChange={handleVisibilityChange}>
                  <CountUp
                    start={isVisible ? 0 : null}
                    end={2000}
                    duration={2.75}
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      fontFamily: "BrownMedium",
                    }}
                  />
                </VisibilitySensor>
                <span style={{ fontFamily: "BrownMedium" }}>+</span> <br />{" "}
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "BrownMedium",
                  }}
                >
                  {language === 'ar' ? "  متابعين":"Followers"}
                  
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col md={4} xs={12} className="counter-item">
          <div className="flex-center gap-4 me-3">
            <img src={orders} alt="Orders" />
            <div className="flex-center">
              <span>
                <VisibilitySensor onChange={handleVisibilityChange}>
                  <CountUp
                    start={isVisible ? 0 : null}
                    end={2000}
                    duration={2.75}
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      fontFamily: "BrownMedium",
                    }}
                  />
                </VisibilitySensor>
                <span style={{ fontFamily: "BrownMedium" }}>+</span> <br />{" "}
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "BrownMedium",
                  }}
                >
                  {language === 'ar' ? "  الطلبات":"Orders"}

                  
                </span>
              </span>
            </div>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="flex-center gap-4">
            <img src={followers} alt="Followers" />
            <div className="flex-center">
              <span>
                <VisibilitySensor onChange={handleVisibilityChange}>
                  <CountUp
                    start={isVisible ? 0 : null}
                    end={200}
                    duration={2.75}
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      fontFamily: "BrownMedium",
                    }}
                  />
                </VisibilitySensor>
                <span style={{ fontFamily: "BrownMedium" }}>+</span> <br />
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    fontFamily: "BrownMedium",
                  }}
                >
                  {language === 'ar' ? "  مراجعات":"Reviews"}

                  
                </span>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Counters;
