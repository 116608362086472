import React, { useState } from "react"
import "../../styles/Auth/auth.css"
import axios from "../../axios/axios"
import { toast } from "react-toastify"
import Spinner from "./Spinner"
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export default function LoginForm() {
  const language = sessionStorage.getItem("lang") || "en";

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await axios.post("/users/login", {
        email: email,
        password: password,
      })

      toast.success("Welcome to O'CURLS", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      })
      // console.log( response.data)
      setLoading(false)
      sessionStorage.setItem("token", response.data.token)
      sessionStorage.setItem("loggedIn", true)
      sessionStorage.setItem("subscribed", response?.data?.user?.subscribed)
      sessionStorage.setItem("email", response?.data?.user?.email)
      sessionStorage.setItem("cart", 0)
      sessionStorage.setItem("wishList", 0)
      sessionStorage.setItem("address",JSON.stringify(response?.data?.user?.address[0]))

      window.location.replace("/")
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      })
      setLoading(false)
      console.error("Login failed:", error.message)
    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmitForgetPass = async () => {
    try {
      const email = document.getElementById("forgetPassInput").value; // Get the value of the promo code input field
      const response = await axios.post(
        "/users/forgot/",
        {
          email: email,
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      toast.success("Email sent successfully.", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      handleClose()
      // Handle response from the server
      // console.log(response.data); // Log the response data
    } catch (error) {
      // Handle errors
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error submitting promo code:", error);
    }
  };
  return (
    <>
      <div
        className="container"
        style={{ paddingTop: "2rem", paddingBottom: "5rem" }}
      >
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col containerLogin" 
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
          
          >
            <div className="heading">{language === "ar" ?"تسجيل الدخول":"Sign In"}</div>
            <form className="form" onSubmit={handleSubmit}>
              <input
                placeholder={language === "ar" ?"البريد الالكتروني":"E-mail"}
                id="email"
                name="email"
                type="email"
                className="input"
                required=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                placeholder={language === "ar" ?"كلمة السر":"Password"}
                id="password"
                name="password"
                type="password"
                className="input"
                required=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="forgot-password" onClick={handleShow}>
                <a onClick={handleShow}>{language === "ar" ?"نسيت كلمة السر؟":"Forgot Password "}</a>
              </span>
              <button
                type="submit"
                className="custom-btn mt-3"
                style={{ width: "100%" }}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  {loading ? <Spinner /> :  language === "ar" ? "تسجيل الدخول":"Sign In"}
                </span>
              </button>
            </form>
            <div className="social-account-container">
              <span className="title">{ language === "ar" ?"أو قم بالتسجيل":"Or Sign Up"}</span>
              <span className="agreement">
                <a href="/register">{ language === "ar" ?"سجل الآن":"Register Now"}</a>
              </span>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{ language === "ar" ?"ادخل بريدك الإلكتروني":"Apply Your Email"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>Enter promo code</Form.Label> */}
             
              <Form.Control
                type="text"
                id="forgetPassInput"
                autoFocus
                placeholder={ language === "ar" ?"ادخل بريدك الإلكتروني":"Enter your email"}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="custom-btn" onClick={handleSubmitForgetPass}>
            <span>{ language === "ar" ?"حفظ":"Save"} </span>
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  )
}
