import React, { useEffect, useState } from "react";

import axios from "../../axios/axios";
import Modal from "react-bootstrap/Modal";
import Spinner from "../Auth/Spinner";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { isMobile } from "react-device-detect";

export default function Address() {
  const language = sessionStorage.getItem("lang") || "en";

  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get("/users/address", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setAddresses(response.data.addresses);
        // setCartTotal(response.data);

        // console.log(response.data);

        setLoading(false);
      } catch (error) {
        // setError(error);
        setLoading(false);
      }
    };

    fetchWishlistData();
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEditDefault = () => setShowEditDefault(false);
  const handleShowEditDefault = () => setShowEditDefault(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [governorates, setGovernorates] = useState([]);
  const [selectedGovernorate, setSelectedGovernorate] = useState("");
  const [delivery, setDelivery] = useState("");
  const [addressId, setAddressId] = useState("");
  const [addressIdDefault, setAddressIdDefault] = useState("");

  const [selectedGovernorateId, setSelectedGovernorateId] = useState("");
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [loadingEditAddress, setLoadingEditAddress] = useState(false);
  const [loadingEditAddressDefault, setLoadingEditAddressDefault] =
    useState(false);

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);

  //   const [countriesEdit, setCountriesEdit] = useState([]);
  const [selectedCountryEdit, setSelectedCountryEdit] = useState("");
  const [governoratesEdit, setGovernoratesEdit] = useState([]);
  const [selectedGovernorateEdit, setSelectedGovernorateEdit] = useState("");
  const [selectedGovernorateIdEdit, setSelectedGovernorateIdEdit] =
    useState("");
  const [selectedCountryEditDefault, setSelectedCountryEditDefault] =
    useState("");
  const [governoratesEditDefault, setGovernoratesEditDefault] = useState([]);
  const [selectedGovernorateEditDefault, setSelectedGovernorateEditDefault] =
    useState("");
  const [
    selectedGovernorateIdEditDefault,
    setSelectedGovernorateIdEditDefault,
  ] = useState("");

  useEffect(() => {
    // Fetch countries data
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get("/zones/countries");
      setCountries(response.data.countries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchGovernorates = async (countryId) => {
    try {
      const response = await axios.get(`/zones/${countryId}/governorates`);
      setGovernorates(response.data.governorates);
    } catch (error) {
      console.error("Error fetching governorates:", error);
    }
  };
  // console.log(countries)
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    // console.log(selectedCountry);
    setSelectedGovernorate("");
    if (selectedCountry) {
      fetchGovernorates(selectedCountry);
    } else {
      setGovernorates([]);
    }
  };

  const handleGovernorateChange = (event) => {
    const selectedGovernorate = event.target.value; // Get the _id of the selected governorate
    // console.log(selectedGovernorate);
    setSelectedGovernorate(selectedGovernorate);
    setSelectedGovernorateId(selectedGovernorate._id);

    setDelivery(selectedGovernorate.delivery);
  };

  const handleCountryChangeEdit = (event) => {
    const selectedCountryEdit = event.target.value;
    setSelectedCountryEdit(selectedCountryEdit);

    setSelectedGovernorateEdit("");
    if (selectedCountryEdit) {
      fetchGovernorates(selectedCountryEdit);
    } else {
      setGovernoratesEdit([]);
    }
  };

  const handleGovernorateChangeEdit = (event) => {
    const selectedGovernorateEdit = event.target.value; // Get the _id of the selected governorate
    // console.log(selectedGovernorate);
    setSelectedGovernorateEdit(selectedGovernorateEdit);
    setSelectedGovernorateIdEdit(selectedGovernorateEdit._id);

    // setDelivery(selectedGovernorate.delivery);
  };
  const [apartment, setApartment] = useState("");
  const [floor, setFloor] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "apartment":
        setApartment(value);
        break;
      case "floor":
        setFloor(value);
        break;
      case "street":
        setStreet(value);
        break;
      case "building":
        setBuilding(value);
        break;
      case "city":
        setCity(value);
        break;
      case "postalCode":
        setPostalCode(value);
        break;
      default:
        break;
    }
  };
  // const [address, setAddress] = useState({
  //     apartment: '',
  //     floor: '',
  //     street: '',
  //     building: '',
  //     city: "",
  //     postalCode: '',
  //     // country:""
  // });
  // useEffect(() => {
  //     setAddress(prevAddress => ({
  //         ...prevAddress,
  //         city: selectedGovernorateId
  //     }));
  // }, [selectedGovernorate]);

  useEffect(() => {
    setCity(selectedGovernorateId);
  }, [selectedGovernorate]);
  useEffect(() => {
    setCityEdit(selectedGovernorateIdEdit);
  }, [selectedGovernorateEdit]);
  useEffect(() => {
    setCityEditDefault(selectedGovernorateIdEditDefault);
  }, [selectedGovernorateEditDefault]);
  const handleSubmitCheckout = async (e) => {
    setLoadingCheckout(true);

    e.preventDefault();
    try {
      const response = await axios.post(
        "/users/address",
        {
          apartment,
          floor,
          street,
          building,
          city,
          postalCode,
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      setLoadingCheckout(false);
      toast.success("address added successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      handleClose()
     window.location.reload()
      // console.log(response.data); // Handle success response
    } catch (error) {
      setLoadingCheckout(false);
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error:", error); // Handle error
    }
  };
  const handleRemoveAddress = async (addressId) => {
    try {
      await axios.delete(`/users/address/${addressId}`, {
        headers: { Authorization: ` ${sessionStorage.getItem("token")}` },
      });
      toast.success("address deleted successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      const updatedCartData = addresses.filter(item => item._id !== addressId );
      setAddresses(updatedCartData);
      // If deletion is successful, update your addresses state or trigger a re-fetch of addresses.
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error deleting address:", error);
      // Handle error appropriately, e.g., show an error message.
    }
  };
  const [addressToEdit, setAddressToEdit] = useState({});

  // console.log(addressToEdit)
  const [apartmentEdit, setApartmentEdit] = useState("");
  const [floorEdit, setFloorEdit] = useState("");
  const [streetEdit, setStreetEdit] = useState("");
  const [buildingEdit, setBuildingEdit] = useState("");
  const [cityEdit, setCityEdit] = useState("");
  const [postalCodeEdit, setPostalCodeEdit] = useState("");

  const handleAddressChangeEdit = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "apartmentEdit":
        setApartmentEdit(value);
        break;
      case "floorEdit":
        setFloorEdit(value);
        break;
      case "streetEdit":
        setStreetEdit(value);
        break;
      case "buildingEdit":
        setBuildingEdit(value);
        break;
      case "cityEdit":
        setCityEdit(value);
        break;
      case "postalCodeEdit":
        setPostalCodeEdit(value);
        break;
      default:
        break;
    }
  };
  const handleEditAddress = async (e, addressId) => {
    setLoadingEditAddress(true);

    e.preventDefault();
    try {
      const response = await axios.patch(
        `/users/address/${addressId}`,
        {
          apartment: apartmentEdit,
          floor: floorEdit,
          street: streetEdit,
          building: buildingEdit,
          city: cityEdit,
          postalCode: postalCodeEdit,
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      setLoadingEditAddress(false);
      toast.success("address edited successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      handleCloseEdit()
      // window.location.reload()
      // console.log(response.data); // Handle success response
    } catch (error) {
      setLoadingEditAddress(false);
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error:", error); // Handle error
    }
  };

  const handleCountryChangeEditDefault = (event) => {
    const selectedCountryEditDefault = event.target.value;
    setSelectedCountryEditDefault(selectedCountryEditDefault);

    setSelectedGovernorateEditDefault("");
    if (selectedCountryEditDefault) {
      fetchGovernorates(selectedCountryEditDefault);
    } else {
      setGovernoratesEditDefault([]);
    }
  };

  const handleGovernorateChangeEditDefault = (event) => {
    const selectedGovernorateEditDefault = event.target.value; // Get the _id of the selected governorate
    // console.log(selectedGovernorate);
    setSelectedGovernorateEditDefault(selectedGovernorateEditDefault);
    setSelectedGovernorateIdEditDefault(selectedGovernorateEditDefault._id);

    // setDelivery(selectedGovernorate.delivery);
  };

  const [apartmentEditDefault, setApartmentEditDefault] = useState("");
  const [floorEditDefault, setFloorEditDefault] = useState("");
  const [streetEditDefault, setStreetEditDefault] = useState("");
  const [buildingEditDefault, setBuildingEditDefault] = useState("");
  const [cityEditDefault, setCityEditDefault] = useState("");
  const [postalCodeEditDefault, setPostalCodeEditDefault] = useState("");

  const handleAddressChangeEditDefault = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "apartmentEditDefault":
        setApartmentEditDefault(value);
        break;
      case "floorEditDefault":
        setFloorEditDefault(value);
        break;
      case "streetEditDefault":
        setStreetEditDefault(value);
        break;
      case "buildingEditDefault":
        setBuildingEditDefault(value);
        break;
      case "cityEditDefault":
        setCityEditDefault(value);
        break;
      case "postalCodeEditDefault":
        setPostalCodeEditDefault(value);
        break;
      default:
        break;
    }
  };
  const handleEditAddressDefault = async (e, addressIdDefault) => {
    setLoadingEditAddressDefault(true);

    e.preventDefault();
    try {
      const response = await axios.patch(
        `/users/address/${addressIdDefault}`,
        {
          apartment: apartmentEditDefault,
          floor: floorEditDefault,
          street: streetEditDefault,
          building: buildingEditDefault,
          city: cityEditDefault,
          postalCode: postalCodeEditDefault,
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      setLoadingEditAddressDefault(false);
      toast.success("address edited successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      handleCloseEditDefault()
      window.location.reload()
      // console.log(response.data); // Handle success response
    } catch (error) {
      setLoadingEditAddressDefault(false);
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error:", error); // Handle error
    }
  };
  const handleDetDefaultAddress = async (addressIdDefault) => {
    try {
      const response = await axios.patch(
        `/users/setDefaultAddress/${addressIdDefault}`,
        {},
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );

      toast.success("address set Default successfully", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      // console.log(response.data); // Handle success response
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
      console.error("Error:", error); // Handle error
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <div className="typing-indicator">
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
          </div>
        </div>
      ) : (
        <>
          {isMobile ? (
            <div className="container mt-3">
              <div className="row">
                <div className="col">
                  <p
                    style={{
                      backgroundColor: "#fbfbfb",
                      borderBottom: "1px solid #e23b26",
                      padding: "1rem",
                    }}
                  >
                                    {language === "ar" ?"العنوان الافتراضي":" Default Address"}  

                  </p>
                  {/* {addresses.map((address, index) => ( */}

                  <div
                    className="row"
                    // key={index}
                  >
                    <div
                      className="col-md-9"
                      style={{ borderRight: "1px solid #e23b26" }}
                    >
                      <p>
                        {" "}
                        {addresses[0].city.name +
                          " ," +
                          addresses[0].street +
                          " ," +
                          addresses[0].building +
                          " ," +
                          addresses[0].floor +
                          " ," +
                          addresses[0].apartment}
                      </p>
                      <p>{language === "ar" ?"رمز بريدي":"postal code"}: {addresses[0].postalCode}</p>
                    </div>
                    <div className="col-md-3">
                      <div className="row m-2">
                        <button className="custom-btn w-50 mx-auto">
                          {" "}
                          <span
                            onClick={() => {
                              handleShowEditDefault();
                              setAddressIdDefault(addresses[0]._id);
                            }}
                          >
                                                          {language === "ar" ? "تعديل":"Edit"}

                          </span>
                        </button>
                      </div>
                      <div className="row m-2">
                        <button className="custom-btn w-50 mx-auto mb-4">
                          {" "}
                          <span>  {language === "ar" ?"حذف":"remove"}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* // ))} */}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p
                    style={{
                      backgroundColor: "#fbfbfb",
                      borderBottom: "1px solid #e23b26",
                      padding: "1rem",
                    }}
                  >
                                    {language === "ar" ?"عنوان آخر":"Other Address"} 

                  </p>
                  {addresses.slice(1).map((address, index) => (
                    <div className="row" key={index}>
                      <div
                        className="col-md-9"
                        style={{ borderRight: "1px solid #e23b26" }}
                      >
                        <p>
                          {" "}
                          {address?.city.name +
                            " ," +
                            address?.street +
                            " ," +
                            address?.building +
                            " ," +
                            address?.floor +
                            " ," +
                            address?.apartment}
                        </p>
                        <p>{language === "ar" ?"رمز بريدي":"postal code"}: {address?.postalCode}</p>
                      </div>
                      <div className="col-md-3">
                        <div className="row m-2">
                          <button
                            className="custom-btn w-50 mx-auto"
                            onClick={() => {
                              handleShowEdit();
                              setAddressId(address?._id);
                            }}
                          >
                            <span>                              {language === "ar" ? "تعديل":"Edit"}
</span>
                          </button>
                        </div>
                        <div className="row m-2">
                          <button
                            className="custom-btn w-50 mx-auto"
                            onClick={() => handleRemoveAddress(address?._id)}
                          >
                            {" "}
                            <span>{language === "ar" ?"حذف":"remove"}</span>
                          </button>
                        </div>
                        <div className="row m-2">
                          <button
                            className="custom-btn w-50 mx-auto"
                            onClick={() => {
                              handleDetDefaultAddress(address?._id);
                              setAddressId(address?._id);
                            }}
                          >
                            {" "}
                            <span>{language === "ar" ? "تعيين الافتراضي" :"set default"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    className="custom-btn w-50 mx-auto my-4"
                    onClick={handleShow}
                  >
                    {" "}
                    <span> {language === "ar" ?"أضف عنوانًا جديدًا" :" Add new address"}</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col">
                  <p
                    style={{
                      backgroundColor: "#fbfbfb",
                      borderBottom: "1px solid #e23b26",
                      padding: "1rem",
                    }}
                  >
                 {language === "ar" ?"العنوان الافتراضي":" Default Address"}  
                  </p>
                  {/* {addresses.map((address, index) => ( */}

                  <div
                    className="row"
                    // key={index}
                  >
                    <div
                      className={addresses[0] ? "col-md-9" : "col-md-12"}
                      style={isMobile ? { borderRight: "1px solid #e23b26" } : {}}
                    >
                      {addresses[0] ? (
                        <>
                         {language === "ar" ?
                          <p>
                            {" "}
                            {addresses[0]?.city?.nameAr +
                              " ," +
                              addresses[0]?.street +
                              " ," +
                              addresses[0]?.building +
                              " ," +
                              addresses[0]?.floor +
                              " ," +
                              addresses[0]?.apartment}
                          </p>: <p>
                            {" "}
                            {addresses[0]?.city?.name +
                              " ," +
                              addresses[0]?.street +
                              " ," +
                              addresses[0]?.building +
                              " ," +
                              addresses[0]?.floor +
                              " ," +
                              addresses[0]?.apartment}
                          </p>}
                          <p>{language === "ar" ?"رمز بريدي":"postal code"}: {addresses[0]?.postalCode}</p>
                        </>
                      ) : (
                        <h3 className="text-center">{language === "ar" ?"أضف عنوانًا جديدًا":"Add new address"}</h3>
                      )}
                    </div>
                    {addresses[0] && (
                      <div className="col-md-3">
                        <div className="row m-2">
                          <button className="custom-btn">
                            {" "}
                            <span
                              onClick={() => {
                                handleShowEditDefault();
                                setAddressIdDefault(addresses[0]?._id);
                              }}
                            >
                              {language === "ar" ? "تعديل":"Edit"}
                            </span>
                          </button>
                        </div>
                        <div className="row m-2">
                          <button className="custom-btn">
                            {" "}
                            <span>  {language === "ar" ?"حذف":"remove"}</span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* // ))} */}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p
                    style={{
                      backgroundColor: "#fbfbfb",
                      borderBottom: "1px solid #e23b26",
                      padding: "1rem",
                    }}
                  >
                   {language === "ar" ?"عنوان آخر":"Other Address"} 
                  </p>
                  {addresses.slice(1).map((address, index) => (
                    <div className="row" key={index}>
                      <div
                        className="col-md-9"
                        style={{ borderRight: "1px solid #e23b26" }}
                      >
                        <p>
                          {" "}
                          {address?.city?.name +
                            " ," +
                            address?.street +
                            " ," +
                            address?.building +
                            " ," +
                            address?.floor +
                            " ," +
                            address?.apartment}
                        </p>
                        <p>{language === "ar" ?"رمز بريدي":"postal code"} : {address?.postalCode}</p>
                      </div>
                      <div className="col-md-3">
                        <div className="row m-2">
                          <button
                            className="custom-btn"
                            onClick={() => {
                              handleShowEdit();
                              setAddressId(address?._id);
                              setAddressToEdit(address?address:'');

                            }}
                          >
                            <span>                              
                              {language === "ar" ? "تعديل":"Edit"}
</span>
                          </button>
                        </div>
                        <div className="row m-2">
                          <button
                            className="custom-btn"
                            onClick={() => handleRemoveAddress(address?._id)}
                          >
                            {" "}
                            <span> {language === "ar" ?"حذف":"remove"}</span>
                          </button>
                        </div>
                        <div className="row m-2">
                          <button
                            className="custom-btn"
                            onClick={() => {
                              handleDetDefaultAddress(address?._id);
                              setAddressId(address?._id);
                            }}
                          >
                            {" "}
                            <span>{language === "ar" ? "تعيين الافتراضي" :"set default"}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button className="custom-btn" onClick={handleShow}>
                    {" "}
                    <span>{language === "ar" ?"أضف عنوانًا جديدًا" :" Add new address"}</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <Modal show={show} onHide={handleClose} centered 
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      
      >
        <Modal.Header closeButton>
          <Modal.Title>{language === "ar" ?"أضف عنوانًا جديدًا" :"Add new address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row" style={{marginRight:'3%',marginLeft:'3%'}}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                {language === "ar" ?"الدولة":"Country"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  label="Country"
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  {countries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="governorate-label">
                  {language === "ar" ?"محافظة":"Governorate"}
                  </InputLabel>
                <Select
                  labelId="governorate-label"
                  id="governorate-select"
                  value={selectedGovernorate}
                  onChange={handleGovernorateChange}
                  label="Governorate"
                  disabled={!selectedCountry}
                >
                  {governorates.map((governorate) => (
                    <MenuItem key={governorate._id} value={governorate}>
                      {governorate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="Street"
                  name="street"
                  required=""
                  // value={address?.street}
                  onChange={handleAddressChange}
                />
                <label htmlFor="street" className="form__label">
                {language === "ar" ?"شارع":" Street"}
                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="building"
                  required=""
                  name="building"
                  // value={address?.building}
                  onChange={handleAddressChange}
                />
                <label htmlFor="building" className="form__label">
                {language === "ar" ?"عمارة":"building"}
                </label>
              </div>

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="Apartment"
                  required=""
                  name="apartment"
                  // value={address?.apartment}
                  onChange={handleAddressChange}
                />
                <label htmlFor="apartment" className="form__label">
                {language === "ar" ?"شقة":" Apartment"}
                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="floor"
                  required=""
                  name="floor"
                  // value={address?.floor}
                  onChange={handleAddressChange}
                />
                <label htmlFor="floor" className="form__label">
                {language === "ar" ?"الدور":" Floor"}
                </label>
              </div>

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="postalCode"
                  required=""
                  name="postalCode"
                  // value={address?.postalCode}
                  onChange={handleAddressChange}
                />
                <label htmlFor="postalCode" className="form__label">
                {language === "ar" ?"رمز بريدي":" Postal Code"}
                </label>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="custom-btn" onClick={handleSubmitCheckout}>
            <span> {loadingCheckout ? <Spinner /> : language === "ar" ?"حفظ":"Save"} </span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit} centered
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      
      >
        <Modal.Header closeButton>
          <Modal.Title>{language === "ar" ?"تعديل عنوانك":"Edit your address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row" style={{marginLeft:'3%',marginRight:'3%'}}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                {language === "ar" ?"الدولة":"Country"}

                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedCountryEdit}
                  onChange={handleCountryChangeEdit}
                  label="Country"
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  {countries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.name} 
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="governorate-label">               
                   {language === "ar" ?"محافظة":"Governorate"}
</InputLabel>
                <Select
                  labelId="governorate-label"
                  id="governorate-select"
                  value={selectedGovernorateEdit}
                  onChange={handleGovernorateChangeEdit}
                  label="Governorate"
                  disabled={!selectedCountryEdit}
                >
                  {governorates.map((governorate) => (
                    <MenuItem key={governorate._id} value={governorate}>
                      {governorate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="Street"
                  name="streetEdit"
                  required=""
                  // value={address?.street}
                  onChange={handleAddressChangeEdit}
                />
                <label htmlFor="streetEdit" className="form__label">
                {addressToEdit? addressToEdit.street : language === "ar" ?"شارع":" Street"} 
                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder={addressToEdit? addressToEdit.building : "building"}
                  required=""
                  name="buildingEdit"
                  // value={address?.buildingEdit}
                  onChange={handleAddressChangeEdit}
                />
                <label htmlFor="buildingEdit" className="form__label">
                {addressToEdit? addressToEdit.building :language === "ar" ?"عمارة": "Building"}
                </label>
              </div>

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="Apartment"
                  required=""
                  name="apartmentEdit"
                  // value={address?.apartment}
                  onChange={handleAddressChangeEdit}
                />
                <label htmlFor="apartmentEdit" className="form__label">
                {addressToEdit? addressToEdit.apartment : language === "ar" ?"شقة":"Apartment"} 
                  
                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="floor"
                  required=""
                  name="floorEdit"
                  // value={address?.floor}
                  onChange={handleAddressChangeEdit}
                />
                <label htmlFor="floorEdit" className="form__label">
                {addressToEdit? addressToEdit.floor :language === "ar" ?"الدور": "Floor"} 
                  
                </label>
              </div>

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="postalCode"
                  required=""
                  name="postalCodeEdit"
                  //   value={postalCodeEdit}
                  onChange={handleAddressChangeEdit}
                />
                <label htmlFor="postalCodeEdit" className="form__label">
                {addressToEdit? addressToEdit.postalCode :language === "ar" ?"رمز بريدي": "Postal Code"} 
                  
                </label>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="custom-btn"
            onClick={(e) => handleEditAddress(e, addressId)}
          >
            <span> {loadingEditAddress ? <Spinner /> : language === "ar" ?"حفظ":"Save"} </span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditDefault} onHide={handleCloseEditDefault} centered
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      
      >
        <Modal.Header closeButton>
          <Modal.Title>{language === "ar" ?"تعديل عنوانك":"Edit your address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row" style={{marginRight:'3%',marginLeft:'3%'}}>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                {language === "ar" ?"الدولة":"Country"}

                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedCountryEditDefault}
                  onChange={handleCountryChangeEditDefault}
                  label="Country"
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  {countries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="governorate-label">              
                     {language === "ar" ?"محافظة":"Governorate"}
</InputLabel>
                <Select
                  labelId="governorate-label"
                  id="governorate-select"
                  value={selectedGovernorateEditDefault}
                  onChange={handleGovernorateChangeEditDefault}
                  label="Governorate"
                  disabled={!selectedCountryEditDefault}
                >
                  {governorates.map((governorate) => (
                    <MenuItem key={governorate._id} value={governorate}>
                      {governorate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="form__group field">
                <input
                  type="input"
                  className="form__field"
                  placeholder="Street"
                  name="streetEditDefault"
                  required=""
                  // value={address?.street}
                  onChange={handleAddressChangeEditDefault}
                />
                <label htmlFor="streetEditDefault" className="form__label">
                {language === "ar" ?"شارع":" Street"}

                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="building"
                  required=""
                  name="buildingEditDefault"
                  // value={address?.buildingEdit}
                  onChange={handleAddressChangeEditDefault}
                />
                <label htmlFor="buildingEditDefault" className="form__label">
                {language === "ar" ?"عمارة":"building"}

                </label>
              </div>

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="Apartment"
                  required=""
                  name="apartmentEditDefault"
                  // value={address?.apartment}
                  onChange={handleAddressChangeEditDefault}
                />
                <label htmlFor="apartmentEditDefault" className="form__label">
                {language === "ar" ?"شقة":" Apartment"}

                </label>
              </div>
              <br />

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="floor"
                  required=""
                  name="floorEditDefault"
                  // value={address?.floor}
                  onChange={handleAddressChangeEditDefault}
                />
                <label htmlFor="floorEditDefault" className="form__label">
                {language === "ar" ?"الدور":" Floor"}

                </label>
              </div>

              <div className="form__group field">
                <input
                  type="number"
                  className="form__field"
                  placeholder="postalCode"
                  required=""
                  name="postalCodeEditDefault"
                  //   value={postalCodeEdit}
                  onChange={handleAddressChangeEditDefault}
                />
                <label htmlFor="postalCodeEditDefault" className="form__label">
                {language === "ar" ?"رمز بريدي":" Postal Code"}

                </label>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="custom-btn"
            onClick={(e) => handleEditAddressDefault(e, addressIdDefault)}
          >
            <span> {loadingEditAddressDefault ? <Spinner /> : language === "ar" ?"حفظ":"Save"} </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
