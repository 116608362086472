import React from "react";
import { Link } from "react-router-dom";

export default function Copyright() {
  return (
    <>
      <div
        className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5"
        style={{
          paddingTop: "0.25rem",
          textAlign: "center",
          fontFamily: "BrownMedium",
        }}
      >
        <div className="container-fluid ltn__border-top-2">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="ltn__copyright-design clearfix">
                <p style={{ fontWeight: "600" }}>
                  COPYRIGHT &copy; 2024 by{" "}
                  <a
                    href="https://www.neoneg.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#e23b26",
                      fontWeight: "600",
                    }}
                  >
                    NEON{" "}
                    <a
                      onClick={() => {
                        window.open(`https://wa.me/+201113588988`);
                      }}
                      target="_blank"
                    >
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
