import React from "react";
import value from "../../assets/values/values.png";
import "../../styles/values/values.css";
import planet from "../../assets/values/no-preservatives.png";
import planetearth from "../../assets/values/planet-earth.png";
import quality from "../../assets/values/high-quality.png";

export default function Values() {
  const language = sessionStorage.getItem("lang") || "en";

  return (
    <div className="container">
      <div className="row mx-auto ms-md-4">
        <div
          className="col-md-3 col-lg-3 col-sm-12 cardValue m-3"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <img src={planet} width={200} alt="" />
          <p className="cardTitle">
            {language === "ar" ? "  طبيعية" : "Natural"}
          </p>
          <div className="cardContent">
            {language === "ar"
              ? " جميع منتجاتنا مصنوعة من مواد طبيعية للتأكد من حصول شعرك على جميع العناصر الغذائية التي يحتاجها دون التعرض لأي ضرر."
              : "  All our products are made of natural materials to make sure your hair gets all the nutrients it needs without suffering any damage."}{" "}
          </div>
        </div>
        <div
          className="col-md-3 col-lg-3 col-sm-12  cardValue m-3"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <img src={planetearth} width={200} alt="" />
          <p className="cardTitle">
            {language === "ar" ? "  الاستمرارية" : "Sustainability"}
          </p>
          <div className="cardContent">
            {language === "ar"
              ? " نحن ملتزمون بالاستدامة والممارسات الواعية بالبيئة ، ونسعى جاهدين لتقليل بصمتنا البيئية من خلال عمليات التوريد والتعبئة والتصنيع المسؤولة."
              : "We are committed to sustainability and eco-conscious practices, striving to minimize our environmental footprint through responsible sourcing, packaging, and manufacturing processes. "}
          </div>
        </div>
        <div
          className="col-md-3 col-lg-3 col-sm-12  cardValue m-3"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          <img src={quality} width={200} alt="" />
          <p className="cardTitle">
            {language === "ar" ? "  الجوده" : "Quality"}
          </p>
          <div className="cardContent">
            {language === "ar"
              ? " نهدف باستمرار إلى تقديم جودة لا هوادة فيها ، وتجاوز التوقعات ووضع معايير التميز في كل ما نقوم به."
              : "We aim to consistently deliver uncompromising quality, exceeding expectations and setting the standard for excellence in everything we do. "}
          </div>
        </div>
      </div>
    </div>
  );
}
