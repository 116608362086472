import React from "react";
import header from "../../assets/header/blog.png";
export default function BlogHeader() {
  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          {/* <div className="col-md-1"></div>
          <div className="col-md-3">
            <div
              className="row"
              style={{ alignItems: "center", height: "100%" }}
            >
              <div>
                <h5>
                  Home <span> - </span> Blog
                </h5>
                <h2>Blog</h2>
              </div>
            </div>
          </div> */}
          <div className="col-md-12 mt-5 pt-5 pe-2" style={{ paddingRight: "0" }}>
            <img src={header} alt="" className="headerBg" />
          </div>
        </div>
      </div>
    </>
  );
}
