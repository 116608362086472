import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import { Col, Row, Form, Alert } from "react-bootstrap";
import defImage from "../../assets/def.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import element3 from '../../assets/PNG/ELEMNTS-17.png'

export default function EditProfile() {
  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    lastName: "",
    // file: null,
  });
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const language = sessionStorage.getItem("lang") || "en";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setProfileData(response.data);
        // console.log(response.data);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData({ ...profileData, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleEdit = (e) => {
    setLoading(true)
    e.preventDefault();
    const fileInput = document.getElementById("imageInput");
    const file = fileInput.files[0];
    
    // Create FormData instance
    const formDataToSend = new FormData();
  
    // Iterate through formData to append only updated fields
    Object.keys(formData).forEach((key) => {
      // Check if the field is updated and not empty
      if (formData[key] !== "" && formData[key] !== profileData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    // Append file if it's present
    if (file) {
      formDataToSend.append("file", file);
    }
  
    axios
      .put("/users/update", formDataToSend, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success("Profile updated successfully.", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
        setFormData({
          firstName: "",
          phone: "",
          lastName: "",
        });
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <>
      {loading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <div className="typing-indicator">
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
          </div>
        </div>
      ) : (
        <Form className="form mb-4"  {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}>
          <Row className="mb-3">
            <div
              className="imgdiv w-100 mx-auto mb-5"
              style={{
                position: "relative",
                height: "20vh",
                maxWidth: "16vw",
              }}
            >
              {isMobile ? (
                <div className="w-100 mx-auto pb-0 mb-0">
                  <img
                    src={profileData.image ? profileData.image : defImage}
                    alt="User Image"
                    style={{
                      width: "30vw",
                      borderRadius: "50%",
                      height: "30vw",
                      marginLeft:"-50px",
                      marginBottom: "0",
                      marginTop:"30px"
                    }}
                  />
                </div>
              ) : (
                <img
                  src={profileData.image ? profileData.image : defImage}
                  alt="User Image"
                  className="rounded-circle"
                  style={{ width: "8vw", height: "8vw", marginLeft:"70px" , marginBottom:"20px" }}
                />
              )}
              {isMobile ? (
                <label
                  htmlFor="imageInput"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    left: "40px",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-pencil fa-lg"
                    title="Change Image"
                    style={{ fontSize: "20px", color: "#fff" }}
                  ></i>
                </label>
              ) : (
                <label
                  htmlFor="imageInput"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "60px",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fa fa-pencil fa-lg"
                    title="Change Image"
                    style={{ fontSize: "20px", color: "#fff" }}
                  ></i>
                </label>
              )}
              <input
                id="imageInput"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: "none" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: "-10px",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#fff",
                  transform: "rotate(45deg)",
                  zIndex: -1,
                }}
              ></div>
            </div>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              // xs={isMobile ? 12 : 0}
              controlId="formGridEmail"
            >
              <Form.Label className="pb-0 mb-0"> {language === "ar" ?"الاسم الأول": "First Name"}</Form.Label>
              <Form.Control
                name="firstName"
                className="input"
                type="text"
                placeholder={profileData.firstName}
                value={formData.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              // xs={isMobile ? 12 : 0}
              controlId="formGridEmail"
            >
              <Form.Label className="pb-0 mb-0" >{language === "ar" ?"الاسم الاخير":"Last Name"}</Form.Label>
              <Form.Control
                className="input"
                type="text"
                name="lastName"
                placeholder={profileData.lastName}
                value={formData.lastName}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridAddress1">
              <Form.Label>{language === "ar" ?"رقم الهاتف":"Phone"}</Form.Label>
              <Form.Control
                className="input"
                placeholder={profileData.phone}
                value={formData.phone}
                onChange={handleChange}
                name="phone"
              />
            </Form.Group>
            <Form.Group as={Col} xs={0} controlId="formGridAddress1">
              <Form.Label>{language === "ar" ?"البريد الإلكتروني":"Email"}</Form.Label>
              <Form.Control
                className="input"
                placeholder={profileData.email}
                disabled
              />
            </Form.Group>
          </Row>

          <div className="cart-shiping-update-wrapper">
            <div className="cart-shiping-update d-flex flex-column mt-4">
              <Link>
                <button
                  onClick={(e) => {
                    handleEdit(e);
                  }}
                  className="custom-btn mb-md-0 mb-4"
                >
                  <span>{language === "ar" ?"تأكيد ":" Submit Changes"}</span>
                </button>
              </Link>
              {/* <img src={element3} style={{width:"90px"}} alt="" /> */}
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
