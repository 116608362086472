import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from "./axios/axios";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await axios.get("/users/cart", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setCartData(response.data.cart);
        // Update the cart count with the length of the fetched cart data
        setCartCount(response.data.cart.length);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchCartData();
  }, []);

  const updateCartCount = (count) => {
    setCartCount(count);
  };

  return (
    <CartContext.Provider value={{ cartData, cartCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
