import React ,{useEffect} from 'react'
import ContactHeader from '../components/Header/ContactHeader'
import ContactMain from '../components/Contact/ContactMain'

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<ContactHeader/>
<ContactMain/>
</>  )
}
