import React, { useEffect, useState } from "react";
import CartHeader from "../components/Header/CartHeader";
import WishlistTable from "../components/Wishlist/WishlistTable";
import axios from "../axios/axios";
import Spinner from "../components/Auth/Spinner";
import WikshlistShopStyle from "../components/Wishlist/wishlistshopstyle";
export default function Wishlist() {
  const [wishlistData, setWishlistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const perPage = 6;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  } const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get(`/users/wishlist?page=${currentPage}&perPage=${perPage}`, {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setWishlistData(response.data.wishlist);
        console.log(response.data.wishlist);
        setTotalPages(response.data.pages);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchWishlistData();
  }, []);
  const columns = [
    {
        Header: '', // Empty header for the delete button column
        accessor: 'product._id', // Accessor for the product ID
        Cell: ({ row }) => ( // Use 'row' to access the entire row data
        <button onClick={() => handleRemoveFromWishlist(row.original.product._id, row.original.product.variant._id)}>X</button>
      ),
      },
    {
      Header: "Image",
      accessor: (row) => {
        const imageUrl = row.product?.images?.[0]; // Using optional chaining operator
        // console.log(imageUrl); // Log the value
        return imageUrl ? (
          <img
            src={imageUrl}
            alt="Product"
            style={{ width: "150px", height: "16vh" }}
          />
        ) : null; // Return null if imageUrl is falsy
      },
      
    },
    {
      Header: "Name",
      accessor: "product.name",
    },
    {
      Header: "Price",
      accessor: "product.variant.price",
    },
  ];
  const handleRemoveFromWishlist = async (productId, variantId) => {
    try {
      // Make DELETE request to remove product from wishlist
      await axios.delete(`/users/removeFromWishlist/${productId}/${variantId}`);
      // Assuming you have a function to fetch updated wishlist data, you can call it here
      // fetchWishlistData();
      console.log('Product removed from wishlist successfully');
    } catch (error) {
      console.error('Error removing product from wishlist:', error);
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <CartHeader />
      {loading ? (
         <div
         style={{
           justifyContent: "center",
           display: "flex",
           width: "100%",
           height: "50vh",
           alignItems: "center",
         }}
       >
         <div className="typing-indicator">
           <div className="typing-circle"></div>
           <div className="typing-circle"></div>
           <div className="typing-circle"></div>
           <div className="typing-shadow"></div>
           <div className="typing-shadow"></div>
           <div className="typing-shadow"></div>
         </div>
       </div>
      ) : (
        <>
          
          {/* {wishlistData.length>0?<WishlistTable data={wishlistData} columns={columns}/>:<h1 className="fw-bold my-5 text-center">Add products first</h1>} */}
      {wishlistData.length>0?<WikshlistShopStyle wishlistData={wishlistData} />:""}  

 
      <div
              className="col-12"
              style={{
                justifyContent: "center",
                display: "flex",
                position: "absolute",
                bottom: "0",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {currentPage !== 1 && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${currentPage === 1 ? "disabled" : ""
                          }`}
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </a>
                    </li>
                  )}
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <li className="page-item" key={startPage + index}>
                        <a
                          className={`page-link ${currentPage === startPage + index ? "active" : ""
                            }`}
                          onClick={() => handlePageChange(startPage + index)}
                          disabled={currentPage === startPage + index}
                        >
                          {startPage + index}
                        </a>
                      </li>
                    )
                  )}
                  {currentPage !== NoOfPages && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${currentPage === NoOfPages ? "disabled" : ""
                          }`}
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
        </>
      )}
    </>
  );
}
