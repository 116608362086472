import React, { useRef, useEffect, useState } from "react";
// import Swiper from "swiper"
import "../../styles/featuredProduct/featuredProduct.css";
import "swiper/css"; // Import Swiper CSS
import bannerImg from "../../assets/banner/banner.jpg";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import "../../styles/shop/shop.css";
import Slider from "@mui/material/Slider";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { Link } from "react-router-dom";
import axios from "../../axios/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import { useWishlist } from "../../WishlistContext";
import { useCart } from "../../CartContext";
import Spinner from "../Auth/Spinner";
import { isMobile } from "react-device-detect";

export default function ShopProducts() {
  const { updateWishlistCount } = useWishlist();
  const { updateCartCount } = useCart();
  const language = sessionStorage.getItem("lang") || "en";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const pp = ["1", "2", "3", "4", "5", "6"];
  const hairTypes = ["2A", "3A", "4A", "2B", "3B", "4B", "2C", "3C", "4C"];
  const swiperRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [price, setPrice] = useState({});
  const [value, setValue] = useState([0, 20000]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedHairTypes, setSelectedHairTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [activeVariant, setActiveVariant] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [priceValue, setPriceValue] = useState([]);
  const [selectedWeights, setSelectedWeights] = useState([]);
  const [product, setProduct] = useState();
  const [reload, setReload] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const perPage = 9;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  }
  useEffect(() => {
    if (pp.length > 0 && swiperRef.current) {
      new Swiper(swiperRef.current, {
        slidesPerView: 4,
        // Add any other Swiper options you need
      });
    }
  }, [pp]);
  //  console.log(price)
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPriceValue(newValue);
  };
  const valuetext = (value) => {
    return `$${Math.floor(value)}`;
  };
  const handleWeightChange = (weight) => {
    // console.log(weight);
    if (selectedWeights.includes(weight)) {
      setSelectedWeights(selectedWeights.filter((w) => w !== weight));
    } else {
      setSelectedWeights([...selectedWeights, weight]);
    }
  };

  useEffect((_) => {
    setLoading(true);
    axios
      .get("/products/categories")
      .then((res) => {
        setCategories(res.data?.categories);
        // console.log(res.data);
      })
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading(false));
    axios
      .get("/products/subcategories")
      .then((res) => {
        setSubCategories(res.data);
      })
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading1(false));
  }, []);

  const fetchProducts = () => {
    setLoading2(true);
    axios
      .get(`/products/all?page=${currentPage}&perPage=${perPage}`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setProducts(res?.data?.products);
        setPrice(res.data);
        setTotalPages(res.data.pages);
        setLoading2(false);
      })
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading2(false));
  };
  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const filter = () => {
    let tempUrl = `/products/all?`;
    let setSelectedCategories = new Set(selectedCategories);
    let setHairType = new Set(selectedHairTypes);
    Array.from(setSelectedCategories).map((category, index) => {
      tempUrl += `&category[${index}]=${category}`;
    });
    Array.from(setHairType).map((hairType, index) => {
      tempUrl += `&hairType[${index}]=${hairType}`;
    });
    let setSelectedSubCategories = new Set(selectedSubCategories);
    Array.from(setSelectedSubCategories).map((subCategory, index) => {
      tempUrl += `&subcategory[${index}]=${subCategory}`;
    });
    // tempUrl += `&priceFrom=${priceValue[0] || 0}&priceTo=${
    //   priceValue[1] || 200000
    // }`;
    selectedWeights.forEach((weight, index) => {
      tempUrl += `&volume[${index}]=${weight}`;
    });

    axios
      .get(tempUrl)
      .then((res) => {
        setProducts(res.data?.products);
      })
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading2(false));
  };
  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedHairTypes([]);
    setSelectedSubCategories([]);
    setPriceValue([0, 200000]);
    setSelectedWeights([]);
    fetchProducts(); // Fetch products with default filters after resetting
  };

  const token = sessionStorage.getItem("token");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [wishlistStatus, setWishlistStatus] = useState({});

  // Update the local state upon adding or removing an item from the wishlist
  const updateWishlistStatus = (productId, isFav) => {
    setWishlistStatus((prevStatus) => ({
      ...prevStatus,
      [productId]: isFav,
    }));
  };

  const addToWishlist = (productId, variantId) => {
    axios
      .post(
        "users/addToWishList",
        {
          productId: productId,
          variantId: variantId,
        },
        { headers: { Authorization: sessionStorage.getItem("token") } }
      )
      .then((response) => {
        sessionStorage.setItem(
          "wishList",
          parseInt(sessionStorage.getItem("wishList")) + 1
        );
        updateWishlistStatus(productId, true);
        setReload(!reload);
        updateWishlistCount((previousCount) => previousCount + 1);
        fetchProducts();
        toast.success("product added to your wishlist", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      });
  };

  const removeFromWishlist = (productId, variantId) => {
    axios
      .delete("users/removeFromWishList/" + productId + "/" + variantId, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((response) => {
        sessionStorage.setItem(
          "wishList",
          parseInt(sessionStorage.getItem("wishList")) - 1
        );
        updateWishlistStatus(productId, false);
        setReload(!reload);
        fetchProducts();
        updateWishlistCount((previousCount) => previousCount - 1);
        toast.success("product removed from your wishlist", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      });
  };

  const handleAddtoWishList = (check, productId, variantId) => {
    if (check) {
      addToWishlist(productId, variantId);
    } else {
      removeFromWishlist(productId, variantId);
    }
  };

  const handleAddtoCart = (check, productId, variantId) => {
    if (!check) {
      axios
        .post(
          "users/addToCart",
          {
            productId: productId,
            variantId: variantId,
            quantity: quantity,
          },
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) + 1
          );
          setQuantity(1);
          setReload(!reload);
          updateCartCount((previousCount) => previousCount + 1);

          fetchProducts();
          toast.success("product added to your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    } else {
      axios
        .delete("users/removeFromCart/" + productId + "/" + variantId, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) - 1
          );
          setReload(!reload);
          fetchProducts();
          updateCartCount((previousCount) => previousCount - 1);

          toast.success("product removed from your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    }
  };

  return (
    <>
      <div className="container" style={{ marginTop: isMobile ? "0" : "3rem" }}>
        <div
          className="row"
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
        >
          {isMobile ? (
            <div>
              <button
                className="fillterbtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                {language === "ar" ? "عرض النتائج" : "Filter"}{" "}
                <i className="fa-solid fa-filter"></i>
              </button>

              <div
                className="offcanvas offcanvas-bottom"
                tabIndex="-1"
                id="offcanvasBottom"
                {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
                aria-labelledby="offcanvasBottomLabel"
              >
                <div
                  className="offcanvas-header w-100"
                  {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
                >
                  <h5 className="offcanvas-title" id="offcanvasBottomLabel">
                    {language === "ar" ? "عرض النتائج" : "Filter Products"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body w-100 d-flex justify-content-center ms-4">
                  <div className="col-12">
                    <div
                      className="row"
                      style={{ paddingRight: "3rem" }}
                      {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
                    >
                      <div
                        className="categoryShop"
                        {...(language === "ar"
                          ? { dir: "rtl", lang: "ar" }
                          : {})}
                      >
                        <p> {language === "ar" ? "التصنيفات" : "Categories"}</p>
                        <div className="checkbox-wrapper-46">
                          {!loading &&
                            categories &&
                            Array.isArray(categories) &&
                            categories.length > 0 &&
                            categories.map((one, index) => (
                              <>
                                <input
                                  type="checkbox"
                                  id={`cbx-${40 + index}`}
                                  className="inp-cbx"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let t = [...selectedCategories];
                                      t.push(one._id);
                                      setSelectedCategories([...t]);
                                    } else {
                                      selectedCategories.map(
                                        (oneSelected, selectedIndex) => {
                                          if (oneSelected._id == one._id) {
                                            let t = [...selectedCategories];
                                            t.splice(selectedIndex, 1);
                                            setSelectedCategories([...t]);
                                          }
                                        }
                                      );
                                    }
                                    // console.log(selectedCategories);
                                    // console.log(
                                    //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                    // )
                                  }}
                                  key={one._id}
                                />
                                <label
                                  htmlFor={`cbx-${40 + index}`}
                                  className="cbx"
                                >
                                  <span>
                                    <svg
                                      viewBox="0 0 12 10"
                                      height="10px"
                                      width="12px"
                                    >
                                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                  </span>
                                  <span>
                                    {language === "ar" ? one.nameAr : one.name}
                                  </span>
                                </label>
                                <br />
                              </>
                            ))}
                          {/* <input type="checkbox" id="cbx-47" className="inp-cbx" />
  <label htmlFor="cbx-47" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Makeup</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-48" className="inp-cbx" />
  <label htmlFor="cbx-48" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Haircare</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-49" className="inp-cbx" />
  <label htmlFor="cbx-49" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Bath & Body</span>
  </label>
  <br />
  <input type="checkbox" id="cbx-50" className="inp-cbx" />
  <label htmlFor="cbx-50" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Nail care</span>
  </label> */}
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="row"
                      style={{ paddingRight: "3rem", marginTop: "1rem" }}
                    >
                      <div className="categoryShop">
                        <p>Sub Category</p>
                        <div className="checkbox-wrapper-46">
                          {!loading1 &&
                            subCategories &&
                            Array.isArray(subCategories) &&
                            subCategories.length > 0 &&
                            subCategories.map((one, index) => (
                              <>
                                <input
                                  type="checkbox"
                                  id={`cbx-${100 + index}`}
                                  className="inp-cbx"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let t = [...selectedSubCategories];
                                      t.push(one._id);
                                      setSelectedSubCategories([...t]);
                                    } else {
                                      selectedSubCategories.map(
                                        (oneSelected, selectedIndex) => {
                                          if (oneSelected._id == one._id) {
                                            let t = [...selectedSubCategories];
                                            t.splice(selectedIndex, 1);
                                            setSelectedSubCategories([...t]);
                                          }
                                        }
                                      );
                                    }
                                    console.log(selectedSubCategories);
                                    // console.log(
                                    //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                    // )
                                  }}
                                />
                                <label
                                  htmlFor={`cbx-${100 + index}`}
                                  className="cbx"
                                >
                                  <span>
                                    <svg
                                      viewBox="0 0 12 10"
                                      height="10px"
                                      width="12px"
                                    >
                                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                  </span>
                                  <span>{one.name}</span>
                                </label>
                                <br />
                              </>
                            ))}
                          {/* 
  <input type="checkbox" id="cbx-2" className="inp-cbx" />
  <label htmlFor="cbx-2" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>PureGlow</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-3" className="inp-cbx" />
  <label htmlFor="cbx-3" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Radiant Skincare</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-4" className="inp-cbx" />
  <label htmlFor="cbx-4" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>HairLux</span>
  </label> */}
                  </div>
                </div>
                <div
                  className="mt-3 row w-100 justify-content-between"
                  style={{ alignItems: "center" }}
                >
                  <div className="col" style={{display:'flex',justifyContent:'center'}}>
                    <div className="resetButton" onClick={resetFilters}>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                        {language === "ar" ? "إعادة" : "Reset"}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <button
                      className="custom-btn my-4 w-100"
                      style={{ width: "0%" }}
                      onClick={() => filter()}
                    >
                      <span>
                        {" "}
                        {language === "ar" ? "عرض النتائج" : "Filter"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{ paddingRight: "3rem" }}
              >
                {/* <div className="categoryShop">
                        <p>Hair Types</p>
                        <div className="checkbox-wrapper-46">
                          {!loading &&
                            hairTypes &&
                            hairTypes.length > 0 &&
                            hairTypes.map((one, index) => (
                              <>
                                <input
                                  type="checkbox"
                                  id={`cbx-${86 + index}`}
                                  className="inp-cbx"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let t = [...selectedHairTypes];
                                      t.push(one);
                                      setSelectedHairTypes([...t]);
                                    } else {
                                      selectedHairTypes.map(
                                        (oneSelected, selectedIndex) => {
                                          if (oneSelected == one) {
                                            let t = [...selectedHairTypes];
                                            t.splice(selectedIndex, 1);
                                            setSelectedHairTypes([...t]);
                                          }
                                        }
                                      );
                                    }
                                    console.log(selectedHairTypes);
                                    // console.log(
                                    //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                                    // )
                                  }}
                                  key={one}
                                />
                                <label
                                  htmlFor={`cbx-${86 + index}`}
                                  className="cbx"
                                >
                                  <span>
                                    <svg
                                      viewBox="0 0 12 10"
                                      height="10px"
                                      width="12px"
                                    >
                                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                  </span>
                                  <span>{one}</span>
                                </label>
                                <br />
                              </>
                            ))}
                        </div>
                      </div> */}
              </div>

              <div
                className="row"
                style={{ paddingRight: "3rem" }}
              >
                {/* <div className="categoryShop">
                        <p>Volume</p>
                        <div className="checkbox-wrapper-46">
                          <input
                            type="checkbox"
                            id="cbx-6"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("50")}
                          />
                          <label htmlFor="cbx-6" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>50 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-55"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("75")}
                          />
                          <label htmlFor="cbx-55" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>75 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-66"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("100")}
                          />
                          <label htmlFor="cbx-66" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>100 ML</span>
                          </label>
                          <br />

                          <input
                            type="checkbox"
                            id="cbx-7"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("150")}
                          />
                          <label htmlFor="cbx-7" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>150 ML</span>
                          </label>
                          <br />

                          <input
                            type="checkbox"
                            id="cbx-8"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("200")}
                          />
                          <label htmlFor="cbx-8" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>200 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-232"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("220")}
                          />
                          <label htmlFor="cbx-232" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>220 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-9"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("250")}
                          />
                          <label htmlFor="cbx-9" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>250 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-10"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("300")}
                          />
                          <label htmlFor="cbx-10" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>300 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-11"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("400")}
                          />
                          <label htmlFor="cbx-11" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>400 ML</span>
                          </label>
                          <br />
                          <input
                            type="checkbox"
                            id="cbx-12"
                            className="inp-cbx"
                            onChange={() => handleWeightChange("500")}
                          />
                          <label htmlFor="cbx-12" className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>500 ML</span>
                          </label>
                          <br />
                        </div>
                      </div> */}
              </div>
              <div
                className="row"
                style={{
                  paddingRight: "3rem",
                  marginTop: "1rem",
                  justifyContent: "center",
                }}
              >
                {/* <div className="categoryShop">
                <p>Price</p>

                <div className="card-conteiner">
                  <div className="range-slider">
                    <Slider
                      getAriaLabel={() => "Price range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      min={price.lowestPrice || 0}
                      max={price.highestPrice || 500}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {priceValue.length > 0 && (
                        <span style={{ fontSize: "14px" }}>
                          {priceValue[0]} EGP - {priceValue[1]} EGP
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
                {/* <div
                  className="mt-3 d-flex w-100 justify-content-between"
                  style={{ alignItems: "center" }}
                >
                  <div className="w-50">
                    <div className="resetButton" onClick={resetFilters}>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                        {language === "ar" ? "إعادة" : "Reset"}
                      </span>
                    </div>
                  </div>
                  <div className="w-100">
                    <button
                      className="custom-btn my-4 w-100"
                      style={{ width: "0%" }}
                      onClick={() => filter()}
                    >
                      <span>
                        {" "}
                        {language === "ar" ? "عرض النتائج" : "Filter"}
                      </span>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            //     </div>
            //   </div>
            // </div>
            <div className="col-xxl-3">
              <div
                className="row"
                style={
                  language === "ar"
                    ? { paddingLeft: "3rem" }
                    : { paddingRight: "3rem" }
                }
              >
                <div
                  className="categoryShop"
                  {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
                >
                  <p>{language === "ar" ? "التصنيفات" : "Categories"}</p>
                  <div className="checkbox-wrapper-46">
                    {!loading &&
                      categories &&
                      Array.isArray(categories) &&
                      categories.length > 0 &&
                      categories.map((one, index) => (
                        <>
                          <input
                            type="checkbox"
                            id={`cbx-${40 + index}`}
                            className="inp-cbx"
                            onChange={(e) => {
                              if (e.target.checked) {
                                let t = [...selectedCategories];
                                t.push(one._id);
                                setSelectedCategories([...t]);
                              } else {
                                selectedCategories.map(
                                  (oneSelected, selectedIndex) => {
                                    if (oneSelected._id == one._id) {
                                      let t = [...selectedCategories];
                                      t.splice(selectedIndex, 1);
                                      setSelectedCategories([...t]);
                                    }
                                  }
                                );
                              }
                              // console.log(selectedCategories);
                              // console.log(
                              //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                              // )
                            }}
                            key={one._id}
                          />
                          <label htmlFor={`cbx-${40 + index}`} className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>
                              {language === "ar" ? one.nameAr : one.name}
                            </span>
                          </label>
                          <br />
                        </>
                      ))}
                    {/* <input type="checkbox" id="cbx-47" className="inp-cbx" />
  <label htmlFor="cbx-47" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Makeup</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-48" className="inp-cbx" />
  <label htmlFor="cbx-48" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Haircare</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-49" className="inp-cbx" />
  <label htmlFor="cbx-49" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Bath & Body</span>
  </label>
  <br />
  <input type="checkbox" id="cbx-50" className="inp-cbx" />
  <label htmlFor="cbx-50" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Nail care</span>
  </label> */}
                  </div>
                </div>
              </div>
              {/* <div
                className="row"
                style={{ paddingRight: "3rem", marginTop: "1rem" }}
              >
                <div className="categoryShop">
                  <p>Sub Category</p>
                  <div className="checkbox-wrapper-46">
                    {!loading1 &&
                      subCategories &&
                      Array.isArray(subCategories) &&
                      subCategories.length > 0 &&
                      subCategories.map((one, index) => (
                        <>
                          <input
                            type="checkbox"
                            id={`cbx-${100 + index}`}
                            className="inp-cbx"
                            onChange={(e) => {
                              if (e.target.checked) {
                                let t = [...selectedSubCategories];
                                t.push(one._id);
                                setSelectedSubCategories([...t]);
                              } else {
                                selectedSubCategories.map(
                                  (oneSelected, selectedIndex) => {
                                    if (oneSelected._id == one._id) {
                                      let t = [...selectedSubCategories];
                                      t.splice(selectedIndex, 1);
                                      setSelectedSubCategories([...t]);
                                    }
                                  }
                                );
                              }
                              console.log(selectedSubCategories);
                              // console.log(
                              //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                              // )
                            }}
                          />
                          <label htmlFor={`cbx-${100 + index}`} className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>{one.name}</span>
                          </label>
                          <br />
                        </>
                      ))}
                    {/* 
  <input type="checkbox" id="cbx-2" className="inp-cbx" />
  <label htmlFor="cbx-2" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>PureGlow</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-3" className="inp-cbx" />
  <label htmlFor="cbx-3" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>Radiant Skincare</span>
  </label>
  <br />

  <input type="checkbox" id="cbx-4" className="inp-cbx" />
  <label htmlFor="cbx-4" className="cbx"
    ><span>
      <svg viewBox="0 0 12 10" height="10px" width="12px">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span><span>HairLux</span>
  </label> */}
              {/* </div>
                </div>
              </div> */}

              {/* <div
                className="row"
                style={{ paddingRight: "3rem", marginTop: "1rem" }}
              >
                <div className="categoryShop">
                  <p>Hair Types</p>
                  <div className="checkbox-wrapper-46">
                    {!loading &&
                      hairTypes &&
                      hairTypes.length > 0 &&
                      hairTypes.map((one, index) => (
                        <>
                          <input
                            type="checkbox"
                            id={`cbx-${86 + index}`}
                            className="inp-cbx"
                            onChange={(e) => {
                              if (e.target.checked) {
                                let t = [...selectedHairTypes];
                                t.push(one);
                                setSelectedHairTypes([...t]);
                              } else {
                                selectedHairTypes.map(
                                  (oneSelected, selectedIndex) => {
                                    if (oneSelected == one) {
                                      let t = [...selectedHairTypes];
                                      t.splice(selectedIndex, 1);
                                      setSelectedHairTypes([...t]);
                                    }
                                  }
                                );
                              }
                              console.log(selectedHairTypes);
                              // console.log(
                              //   e.target.__reactFiber$2dvo5hs8rz1.alternate.key
                              // )
                            }}
                            key={one}
                          />
                          <label htmlFor={`cbx-${86 + index}`} className="cbx">
                            <span>
                              <svg
                                viewBox="0 0 12 10"
                                height="10px"
                                width="12px"
                              >
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                              </svg>
                            </span>
                            <span>{one}</span>
                          </label>
                          <br />
                        </>
                      ))}
                  </div>
                </div>
              </div> */}

              {/* <div
                className="row"
                style={{ paddingRight: "3rem", marginTop: "1rem" }}
              >
                <div className="categoryShop">
                  <p>Volume</p>
                  <div className="checkbox-wrapper-46">
                    <input
                      type="checkbox"
                      id="cbx-6"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("50")}
                    />
                    <label htmlFor="cbx-6" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>50 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-55"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("75")}
                    />
                    <label htmlFor="cbx-55" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>75 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-66"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("100")}
                    />
                    <label htmlFor="cbx-66" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>100 ML</span>
                    </label>
                    <br />

                    <input
                      type="checkbox"
                      id="cbx-7"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("150")}
                    />
                    <label htmlFor="cbx-7" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>150 ML</span>
                    </label>
                    <br />

                    <input
                      type="checkbox"
                      id="cbx-8"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("200")}
                    />
                    <label htmlFor="cbx-8" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>200 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-2323"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("220")}
                    />
                    <label htmlFor="cbx-2323" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>220 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-9"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("250")}
                    />
                    <label htmlFor="cbx-9" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>250 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-10"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("300")}
                    />
                    <label htmlFor="cbx-10" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>300 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-11"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("400")}
                    />
                    <label htmlFor="cbx-11" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>400 ML</span>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      id="cbx-12"
                      className="inp-cbx"
                      onChange={() => handleWeightChange("500")}
                    />
                    <label htmlFor="cbx-12" className="cbx">
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>500 ML</span>
                    </label>
                    <br />
                  </div>
                </div>
              </div> */}
              <div
                className="row"
                style={
                  language === "ar"
                    ? {
                        paddingLeft: "3rem",
                        marginTop: "1rem",
                        justifyContent: "center",
                      }
                    : {
                        paddingRight: "3rem",
                        marginTop: "1rem",
                        justifyContent: "center",
                      }
                }
              >
                {/* <div className="categoryShop">
                <p>Price</p>

                <div className="card-conteiner">
                  <div className="range-slider">
                    <Slider
                      getAriaLabel={() => "Price range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      min={price.lowestPrice || 0}
                      max={price.highestPrice || 500}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {priceValue.length > 0 && (
                        <span style={{ fontSize: "14px" }}>
                          {priceValue[0]} EGP - {priceValue[1]} EGP
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="row mt-3" style={{ alignItems: "center" }}>
                  <div
                    className="col-md-5"
                    style={{ display: "flex", justifyContent: "start" }}
                  >
                    <div className="resetButton" onClick={resetFilters}>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-rotate-left"></i>
                        {language === "ar" ? "إعادة" : "Reset"}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-7"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <button
                      className="custom-btn "
                      style={{ width: "100%" }}
                      onClick={() => filter()}
                    >
                      <span>
                        {language === "ar" ? "عرض النتائج" : "Filter"}{" "}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="col-md-9 overflow-hidden"
            style={{ position: "relative" }}
          >
            <div
              className="row"
              style={{ marginBottom: "2rem", justifyContent: "space-between" }}
            >
              {/* <div className="col">
                <div className="InputContainerSearch">
                  <input
                    placeholder="Search.."
                    id="input"
                    className="inputSearch"
                    name="text"
                    type="text"
                  />
                </div>
              </div> */}
            </div>
            {loading2 ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  width: "100%",
                  height: "20vh",
                  alignItems: "end",
                }}
              >
                <div className="typing-indicator">
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                </div>
              </div>
            ) : (
              <div className="row ">
                {!loading2 &&
                  products &&
                  Array.isArray(products) &&
                  products.length > 0 &&
                  products.map((one) => (
                    <div
                      className="col-lg-4 col-md-5"
                      key={one._id}
                      style={{
                        paddingRight: isMobile ? "2rem" : "1rem",
                        paddingLeft: isMobile ? "2rem" : "1rem",
                      }}
                    >
                      <div className="swiper-slide">
                        <div
                          className="item"
                          style={{
                            marginBottom: "4rem",
                            border: "1px solid #e23b26",
                          }}
                        >
                          <div className="image">
                            <Swiper
                              // spaceBetween={30}
                              slidesPerView={1}
                              // onSlideChange={() => console.log("slide change")}
                              // onSwiper={(swiper) => console.log(swiper)}
                            >
                              {" "}
                              {one.images.map((image, index) => (
                                <SwiperSlide key={index}>
                                  <Link to={`/singleProduct/${one._id}`}>
                                    <img
                                      className="w-100 h-100"
                                      style={{
                                        maxHeight: "440px",
                                        minHeight: "440px",
                                      }}
                                      src={image}
                                      alt={`Banner `}
                                    />
                                  </Link>
                                </SwiperSlide>
                              ))}{" "}
                            </Swiper>

                            {one.variants[0]?.stock > 0 &&
                            one.variants[0]?.isSale === true ? (
                              <div className="discountShop">
                                {one.variants[0]?.salePercentage}% <br />{" "}
                                {language === "ar" ? "خصم" : "off"}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* // variants.map((variant, index) => */}
                            {one.variants[0].isAvailable === true ? (
                              <div
                                className="cartButtonProduct"
                                style={{ zIndex: "9999" }}
                                // key={index}
                              >
                                <button
                                  className="cart"
                                  onClick={() => {
                                    if (sessionStorage.getItem("token")) {
                                      handleAddtoCart(
                                        one.variants[0].inCart,
                                        one._id,
                                        one.variants[0]._id
                                      );
                                    } else {
                                      window.location.replace("/login");
                                    }
                                  }}
                                >
                                  {loadingCart ? (
                                    <Spinner />
                                  ) : (
                                    <>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#fff",
                                          fontSize: "1.3rem",
                                        }}
                                        className="icon"
                                        icon={
                                          one.variants[0].inCart
                                            ? faTimesCircle
                                            : faBasketShopping
                                        } // Change icon based on cart status
                                        size="xl"
                                      />
                                      <span>
                                        {one.variants[0].inCart
                                          ? language === "ar"
                                            ? "إزالة من الحقيبة"
                                            : "REMOVE FROM BAG"
                                          : language === "ar"
                                          ? "إضافة إلى الحقيبة"
                                          : "ADD TO BAG"}
                                      </span>{" "}
                                      {/* Change button text based on cart status */}
                                    </>
                                  )}
                                </button>

                                <button className="heart heart-container">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={one.variants[0].isFav} // Use isFav directly from product data
                                    onChange={(e) => {
                                      if (sessionStorage.getItem("token")) {
                                        const isChecked = e.target.checked;
                                        handleAddtoWishList(
                                          isChecked,
                                          one._id,
                                          one.variants[0]._id
                                        );
                                      } else {
                                        window.location.replace("/login");
                                      }
                                    }}
                                    id="Give-It-An-Id"
                                  />

                                  {one?.variants[0]?.isFav ||
                                  wishlistStatus[one._id] ? (
                                    <i className="fa-solid fa-heart"></i>
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{ fontSize: "1.3rem" }}
                                      className="icon"
                                      icon={faHeart}
                                    />
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div
                                className="cartButtonProduct"
                                style={{ justifyContent: "center" }}
                                // key={index}
                              >
                                <button
                                  className="cart"
                                  style={{ cursor: "default" }}
                                >
                                  <span>
                                    {" "}
                                    {language === "ar"
                                      ? "غير متوفر"
                                      : "OUT OF STOCK"}
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>

                          <Link
                            to={`/singleProduct/${one._id}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <div className="content p-3">
                              {/* <div className="subCategory">
                                {one?.subCategory?.category?.name}
                              </div> */}
                              <div
                                className="title mb-3"
                                style={{
                                  height: "4vh",
                                  fontFamily: "BrownMedium",
                                }}
                              >
                                {language === "ar"
                                  ? one?.nameAr
                                  : one?.name}
                              </div>

                              {/* {one.variants.map((variant, index) => {
    let lowestPrice = Infinity;
    if (variant.price < lowestPrice) {
        lowestPrice = variant.price;
    }
    return ( */}
                              <React.Fragment>
                                <div className="price">
                                  {one.variants[0]?.stock === 0 ? (
                                    <>
                                      <div className="discountShop me-2">
                                        {language === "ar"
                                          ? "غير متوفر  "
                                          : "Out of stock"}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </React.Fragment>
                              <React.Fragment>
                                <div className="price">
                                  {
                                    // one.minPrice === one.maxPrice ? (
                                    //   one.minPrice + " EGP"
                                    // ) :
                                    one.variants[0]?.isSale === true ? (
                                      <>
                                        {one.variants[0]?.price.toFixed(2)} EGP
                                        <span
                                          className="priceShop"
                                          style={{ marginLeft: "8px" }}
                                        >
                                          {one.variants[0]?.price +
                                            one.variants[0]?.saleValue}{" "}
                                          EGP
                                        </span>
                                      </>
                                    ) : (
                                      one.variants[0]?.price.toFixed(2) + " EGP"
                                    )
                                  }
                                </div>
                              </React.Fragment>

                              {/* //     );
// })} */}

                              {one.noOfRatings > 0 ? (
                                <div className="d-flex gap-2">
                                  <Rating
                                    name="read-only"
                                    value={one?.rating}
                                    readOnly
                                    size="small"
                                  />{" "}
                                  <span className="rating">
                                    ({one.noOfRatings}{" "}
                                    {language === "ar"
                                      ? "التقييمات"
                                      : "ratings"}
                                    )
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex gap-2">
                                  <Rating
                                    name="read-only"
                                    value={one?.rating}
                                    readOnly
                                    size="small"
                                  />{" "}
                                  <span className="rating">
                                    ({one.noOfRatings}{" "}
                                    {language === "ar"
                                      ? "التقييمات"
                                      : "ratings"}
                                    )
                                  </span>
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div
              className="col-12"
              style={{
                justifyContent: "center",
                display: "flex",
                position: "absolute",
                bottom: "0",
              }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {currentPage !== 1 && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                        aria-label="Previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </a>
                    </li>
                  )}
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <li className="page-item" key={startPage + index}>
                        <a
                          className={`page-link ${
                            currentPage === startPage + index ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(startPage + index)}
                          disabled={currentPage === startPage + index}
                        >
                          {startPage + index}
                        </a>
                      </li>
                    )
                  )}
                  {currentPage !== NoOfPages && NoOfPages > 1 && (
                    <li className="page-item">
                      <a
                        className={`page-link ${
                          currentPage === NoOfPages ? "disabled" : ""
                        }`}
                        aria-label="Next"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
