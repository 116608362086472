import React ,{useEffect}from 'react'
import LoginHeader from '../components/Header/LoginHeader'
import LoginForm from '../components/Auth/LoginForm'

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<LoginHeader/>
<LoginForm/>

</>  )
}
