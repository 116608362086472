import React from "react";
import { isMobile } from "react-device-detect";
import YouTube from 'react-youtube';

const YouTubePreview = ({ videoURL }) => {
  const opts = {
    height: "590",
    width: "1240",
    paddinTop:'1rem',
    playerVars: {
      autoplay: 1,
    },
  };
  const optsMobile = {
    height: "300",
    width: "350",
    paddinTop:'1rem',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div>
      <YouTube className="mt-4 mb-5 " videoId={videoURL && videoURL.split("youtu.be/")[1]}  opts={isMobile? optsMobile : opts }/>
    </div>
  );
};

export default YouTubePreview;
