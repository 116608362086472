import React ,{useEffect} from 'react'
import RegisterHeader from '../components/Header/RegisterHeader'
import RegisterForm from '../components/Auth/RegisterForm'

export default function Register() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<RegisterHeader/>

<RegisterForm/>
</>  )
}
