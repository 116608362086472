import React, { useEffect, useState } from "react";
import video from "../../assets/banner/banner.jpg";
import axios from "../../axios/axios";
import YouTubePreview from "../common/YouTubePreview";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";

const Video = () => {
  const language = sessionStorage.getItem("lang") || "en";

  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [show, setShow] = useState(false);
  const [video, setShowVideo] = useState("");
  useEffect((_) => {
    // console.log("test");
    setLoading(false);
    axios
      .get("/aboutUs")
      .then((res) => setVideoData(res.data?.aboutUs[0]))
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading(false));
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div
        className="container overflow-hidden"
        style={
          isMobile
            ? { paddingTop: "5%", paddingBottom: "2%", textAlign: "center" }
            : { paddingTop: "5rem", paddingBottom: "2%", textAlign: "center" }
        }
      >
        <div className="row">
          <div className="col">
            <h1 style={{ fontFamily: "Holydayfree" }}>
            {language === "ar"?"  مرحبا بكم في أو كيرلز":"  Welcome to O Curls"}
            </h1>
            <span   {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
              className="p-3 videoStyle fw-bold  "
              style={{ marginBottom: "1rem", fontFamily: "BrownMedium" }}
            >
                 {language === "ar"? 
                 "احتضان حب الذات، كل لفة من الشعر المرنة في وقتها.":"Embracing self-love, one bouncy curl at a time."} <br />
              <span style={{ marginBottom: "1rem" }}>
                {" "}
                {language === "ar"? "احتفل بجمالك الطبيعي معنا!":"  Celebrate your natural beauty with us!"}
              </span>
            </span>{" "}
            <br />
            <a
              onClick={() => {
                handleShow(true);
              }}
              style={{
                cursor: "pointer",
                marginTop: "30px",
                position: "relative",
              }}
            >
          {videoData&&    show && <YouTubePreview videoURL={videoData.video} />}
              {videoData&&!show && (
                <>
                  {isMobile ? (
                    <img
                      className="mb-4 mt-5"
                      width={300}
                      src={videoData&&videoData.image}
                      style={{
                        height: "45vh",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={videoData&&videoData.image}
                      style={{
                        height: "65vh",
                        width: "100%",
                        marginTop: "1rem",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                  <i
                    className="fa-regular fa-circle-play "
                    style={{
                      position: "absolute",
                      top: "40%",
                      right: "46%",
                      fontSize: "3rem",
                      color: "#e23b26",
                    }}
                  ></i>
                </>
              )}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
