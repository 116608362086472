import React,{useState} from 'react'
import { Link, useParams } from "react-router-dom"
import axios from '../axios/axios'
import Spinner from '../components/Auth/Spinner'
import LoginHeader from '../components/Header/LoginHeader'
export default function Forget() {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [error, setError] = useState()
    const params = useParams()
  
    const handleSubmit = () => {
      let password = document.getElementById("password").value
      setLoadingSubmit
      axios
        .patch(`/users/reset/${params.token}`, { password })
        .then((res) => {
          window.location.replace("/")
        })
        .catch((e) => {
          setError("Something went wrong")
        })
        .finally(() => {
          setLoadingSubmit(false)
        })
    }
  
  return (<>
    <LoginHeader/>
    <div className="container">
        <div className="row" style={{justifyContent:'center'}}>
            <div className="col">
                <div className="containerLogin w-100 mx-auto">
                <form className="form"   onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}>
            <label htmlFor="password">New Password</label>
              <input
                placeholder="Password"
                id="password"
                name="password"
                type="password"
                className="input"
                required=""
            
              />
            
              <button
                type="submit"
                className="custom-btn mt-3"
                style={{ width: "100%" }}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  {loadingSubmit ? <Spinner /> : "submit"}
                </span>
              </button>
            </form>
                </div>
            </div>
        </div>
    </div>
  
  </>)
}
