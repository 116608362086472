import React, { useState, useEffect } from "react";
import SingleBlogHeader from "../Header/SingleBlogHeader";
import singleBlogImage from "../../assets/banner/banner.jpg";
import "../../styles/blog/blog.css";
import axios from "../../axios/axios";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
export default function SingleBlog() {
  const language = sessionStorage.getItem("lang") || "en";

  let { pathname } = useLocation();
  const [singleBlog, setSingleBlog] = useState({});
  const match = pathname.match(/^\/singleBlog\/([^/]+)$/);

  // Extract the ID from the match result
  const blogId = match ? match[1] : null;
  // console.log("Extracted ID:", blogId);

  // console.log(match);
  const fetchData = async () => {
    try {
      const response = await axios.get(`/blogs/${blogId}`);
      setSingleBlog(response.data.blog);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);
  return (
    <>
      <SingleBlogHeader title={singleBlog ? singleBlog.titleEnglish : ""} />
      <div className="container mt-md-5 mt-2" style={{ marginTop: "5rem" }}
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      
      >
        <div className="row">
          <div className="col">
            <p className="singleBlogContent">
              {  language === "ar" ?singleBlog && singleBlog.descriptionArabic:singleBlog && singleBlog.descriptionEnglish }
            </p>
            <img
              src={singleBlog ? singleBlog.image : ""}
              style={{ width: "100%", height: `${isMobile?"30vh":"50vh"}`, paddingBottom: "2rem" }}
              alt=""
            />
            {language === "ar" ?singleBlog
              &&singleBlog.bodyArabic?.split("\n").map((one, ind) => (
                  <p
                    className="p-0 m-0 my-1"
                    key={ind}
                    style={{ fontFamily: "BrownMedium" }}
                  >
                    {one}
                  </p>
                )) :singleBlog
              &&singleBlog.bodyEnglish?.split("\n").map((one, ind) => (
                  <p
                    className="p-0 m-0 my-1"
                    key={ind}
                    style={{ fontFamily: "BrownMedium" }}
                  >
                    {one}
                  </p>
                ))
              }
            <p className="singleBlogContent"></p>
          </div>
        </div>
      </div>
    </>
  );
}
