import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from './axios/axios';

const WishlistContext = createContext();

export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
  const [wishlistData, setWishlistData] = useState([]);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const perPage = 6;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  } const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get(`/users/wishlist?page=${currentPage}&perPage=${perPage}`, {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setWishlistData(response.data.wishlist);
        setTotalPages(response.data.pages);

        // Update the wishlist count with the length of the fetched wishlist data
        setWishlistCount(response.data.wishlist.length);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchWishlistData();
  }, []);

  const updateWishlistCount = (count) => {
    setWishlistCount(count);
  };

  return (
    <WishlistContext.Provider value={{ wishlistData, wishlistCount, updateWishlistCount }}>
      {children}
    </WishlistContext.Provider>
  );
};
