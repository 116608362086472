import React, { useEffect, useState } from "react";
import axios from "../../axios/axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

export default function Orders() {
  const language = sessionStorage.getItem("lang") || "en";

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("/users/myOrders", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setOrders(response.data.orders);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const columns = [
    {
      Header: language === "ar" ? "اسم المنتج ":"Product name",
      accessor: (row) => {
        const productNames = row.products.map(
          (product) => product.product.name
        );

        return productNames.join(", ");
      },
    },
    {
      Header: language === "ar" ? "التاريخ":"Date",
      accessor: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      Header: language === "ar" ? "الحاله":"Status",
      accessor: (row) => row.status,
    },
    {
      Header: language === "ar" ? "الاجمالي":"Total",
      accessor: (row) => row.total,
    },
  ];

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.Header}>{column.Header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order, orderIndex) => (
                <TableRow key={orderIndex}>
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>
                      {typeof column.accessor === "function"
                        ? column.accessor(order)
                        : order[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
