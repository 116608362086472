import React from "react"
import "../../styles/banner/banner.css"
import bannerImg from "../../assets/banner/banner.jpg"
const Banner = () => {
  return (
    <div className="banner container-fluid overflow-hidden mt-3" style={{padding:'0'}}>
      <img src={bannerImg} className="bannerBg" ></img>
      {/* <div className="slogan">
        <div className="title">
          Love
          <br /> Your Curls
        </div>
        <div className="content">
          They will love you back!
          <br /> Ola Roshdy
        </div>{" "}
        <div className="flex-center">
          <button className="custom-btn mt-3">
            {" "}
            <span>Take Our Quiz</span>
          </button>
        </div>
      </div> */}
    </div>
  )
}
export default Banner
