import React,{useEffect,useState} from 'react'
import { useParams } from "react-router-dom"
import axios from '../../axios/axios'
import { useLocation } from "react-router-dom"

export default function Verify() {
  const [loading, setLoading] = useState(false)
    
  const location = useLocation()
  // console.log(location.hash.split("#")[1])
  
   
    useEffect(() => {
        setLoading(true)
       
          axios
            .post(`/users/verify/${location.hash.split("#")[1]}`)
            .then((response) => {
              // console.log(response);
              sessionStorage.setItem("token", response.data.token);

             window.location.replace("/");
             setLoading(false)
            })
            .catch((error) => {
             setLoading(false)

              // console.log(error);
            });
      
      }, []);
  return (
<>
{loading?
<div style={{justifyContent:'center',display:"flex",width:'100%',height:'60vh',alignItems:'center'}}>
<p>You will be redirected to the home page. just wait.</p>
<div className="typing-indicator" >
    <div className="typing-circle"></div>
    <div className="typing-circle"></div>
    <div className="typing-circle"></div>
    <div className="typing-shadow"></div>
    <div className="typing-shadow"></div>
    <div className="typing-shadow"></div>
</div></div> :
""}
</>
  )
}
