import React from "react";
import about from "../../assets/about/aboutHome.jpg";
import "../../styles/about/about.css"
import { Link } from "react-router-dom";
import icon from '../../assets/about/ELEMNTS-13.png'
export default function About() {
  const language = sessionStorage.getItem("lang") || "en";

  return (
    <>
      <div className="aboutBg">
        <div className="container aboutContainer">
          <div className="row"   {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}>
            <div className="col-md-6 aboutAll">

              <p className="aboutContent">
                <div className="text-center">
                </div>
                <p className="aboutTitle d-flex justify-content-start align-items-center">  <img src={icon} style={{ width: "40px" }} alt="" />
                  {language === 'ar' ? "  من نحن" : " About us"}

                </p>
                {language === 'ar' ? "مرحبا بكم في O Curls ، حيث يتشابك سحر الجمال المصري مع براعة تجعيد الشعر الطبيعي! بصفتها من المشاهير المصريين المحترمين والمدافعة المتحمسة عن تجعيد الشعر ، تقدم لك علا رشدي مجموعة منسقة ببراعة من منتجات الشعر المجعد الممتازة المصممة للاحتفال بكل نوع من أنواع التجعيد وتعزيزه. من التركيبات الممزوجة بالخلاصات الطبيعية الأكثر روعة إلى طقوس العناية الشخصية المصممة خصيصا لتجعيد الشعر العربي ، تقف علامتنا التجارية حازمة في تمكين جميع الرؤوس المجعدة." : "  Welcome to O Curls, where the enchantment of Egyptian beauty intertwines with the prowess of natural curls! As an esteemed Egyptian celebrity, and fervent curl advocate, Ola Roshdy presents to you an artfully curated collection of premium curly hair products designed to celebrate and enhance every curl type. From formulas infused with the most divine natural essences to personalized care rituals tailored specifically for Arabian curls, our brand stands resolute in empowering all curly heads."}
                <Link to="/about" style={{ textDecoration: 'none' }}><span className="learnAbout">
                {language === 'ar' ? "التعرف على المزيد":"Learn More"}</span></Link>

              </p>

            </div>
            <div className="col-md-6" >
              
              <img className="imgAbout" src={about} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
