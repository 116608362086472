import React from 'react'
import CartHeader from '../components/Header/CartHeader'
import ProfileData from '../components/Profile/ProfileData'

export default function Profile() {
  return (
   <>
   <CartHeader/>
   <ProfileData/>
   </>
  )
}
