import React, { useState, useEffect } from "react";
import NavHeader from "../components/headers/navHeader";
import TopHeader from "../components/headers/TopHeader";
import NavBarHeader from "../components/headers/navBarItemsHeader";
import Banner from "../components/banner/Banner";
import FeaturedProduct from "../components/featuredProduct/FeaturedProduct";
import Counters from "../components/Counters/Counters";
import Discount from "../components/Discount/Discount";
import Values from "../components/Values/Values";
import Tips from "../components/Tips/Tips";
import Founder from "../components/Founder/Founder";
import About from "../components/About/About";
import Feedback from "../components/Feedback/Feedback";
import Trends from "../components/Trends/Trends";
import Subscribe from "../components/common/Subscribe";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const subscribed = JSON.parse(sessionStorage.getItem("subscribed")) || false;
  const [closed, setClosed] = useState(JSON.parse(sessionStorage.getItem("closed")) || false);
  const loggedIn = sessionStorage.getItem("loggedIn") || false;
  

  const closeSubscribeModal = () => {
    setClosed(true);
    sessionStorage.setItem("closed", true);
  } 

  useEffect(() => {
    if(searchParams.get("success")==="true") {
      setSearchParams({})
      toast.success("Order is purchased successfully.", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
    }
    else if(searchParams.get("success")==="false"){
      setSearchParams({})
      toast.error("Order is failed. Please try again!", {
        hideProgressBar: true,
        autoClose: 3000,
        className: "toster"
      });
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <TopHeader />
      <NavHeader />
      <NavBarHeader value={value} setValue={setValue} /> */}
      <div className="mt-5 pt-4">
        <Banner />
        <FeaturedProduct />
        <Discount />
        <Counters />
        <Values />
        {/* <Tips/> */}
        <Founder />
        <About />
        {/* <Feedback/> */}
        <Trends />
      </div>
      {/* {!(loggedIn && subscribed) && !closed && <Subscribe closeFn={closeSubscribeModal}/>} */}
    </>
  );
};
export default Home;
