import React, { useState ,useEffect} from "react"
import AboutHeader from "../components/Header/AboutHeader"
import Mission from "../components/Mission/Mission"
import Video from "../components/video/Video"
import Counters from "../components/Counters/Counters"
import Story from "../components/Story/Story"

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<div className="overflow-hidden">
<AboutHeader/>
<Mission/>
<Video/>
<Counters/>
<Story/>
</div>

</>  )
}
