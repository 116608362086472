import React from "react";
import "../../styles/founder/founder.css";
import founder from "../../assets/founder/founder Image.jpg";
import ola from "../../assets/logo/O Curls website  (1).png";
export default function Founder() {
  return (
    <>
      <div className="container p-3">
        <div className="row">
          <div className="col">
            <div className="cardFounder">
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> */}
              <img src={founder} />
              {/* </svg> */}
            
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
