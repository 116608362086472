import React, { useEffect, useState } from "react";
import tips from "../../assets/tips/caver Tips.png";

import "../../styles/trends/trends.css";
import axios from "../../axios/axios";
import { Link } from "react-router-dom";
export default function Blogs() {
  const language = sessionStorage.getItem("lang") || "en";

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const perPage = 6;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(`blogs?page=${currentPage}&perPage=${perPage}`)
      .then((response) => {
        // console.log(response);
        setBlogs(response.data.blogs);
        setTotalPages(response.data.NoOfPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="container" style={{ paddingTop: "5rem" , paddingBottom: "5rem" }}>
        {loading ? (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <div className="typing-indicator">
              <div className="typing-circle"></div>
              <div className="typing-circle"></div>
              <div className="typing-circle"></div>
              <div className="typing-shadow"></div>
              <div className="typing-shadow"></div>
              <div className="typing-shadow"></div>
            </div>
          </div>
        ) : (
          <div className="row  g-3" style={{ position: "relative" }}
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
          
          >
            {blogs.map((one, index) => {
              return (
                <div key={index} className="col-12 col-md-6 col-lg-4" >
                  <Link
                    to={"/singleBlog/" + one._id}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="cardTrends"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div style={{ width: "100%" }}>
                        <img
                          className="card-imageTrends w-100"
                          style={{height: "200px" , maxHeight: "200px"}}
                          src={one.image}
                        />
                      </div>
                      <div className="headingTrends" 
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
          >
                        {" "}
                        <span
                          className="headingTrendsTitle"
                          style={{
                            lineHeight: "1.5",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "-webkit-box",
                            webkitLineClamp: "1",
                            webkitBoxOsrient: "vertical",
                          }}
                        >
                          {" "}
                          {language === "ar" ?one.titleArabic:one.titleEnglish}
                        </span>
                        <div className="authorTrends" 
          {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
                        
                        >
                          <span

                            style={{
                              lineHeight: "1.5",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "-webkit-box",
                              webkitLineClamp: "2",
                              webkitBoxOsrient: "vertical",
                            }}
                          >
                          {language === "ar" ?one.descriptionArabic:one.descriptionEnglish}

                            
                          </span>
                          <br /> <br /> <span></span>{" "}
                          <div className="row ">
                            <div className="col">
                              <span className="footerTrend">
                                {" "}
                                <i className="fa-solid fa-clock tipsIconTrends"></i>{" "}
                                {new Date(one.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                              </span>{" "}
                            </div>
                            {/* <div className="col">
                            {" "}
                            <span className="footerTrend">
                              {" "}
                              <i className="fa-solid fa-circle-user tipsIconTrends"></i>{" "}
                              May 5, 2023
                            </span>{" "}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
              <div className="row" style={{position:'absolute',bottom:'-70px'}}>
          <div
            className="col-12"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                {currentPage !== 1 && NoOfPages > 1 && (
                  <li className="page-item">
                    <a
                      className={`page-link ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </a>
                  </li>
                )}
                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li className="page-item" key={startPage + index}>
                    <a
                      className={`page-link ${
                        currentPage === startPage + index ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(startPage + index)}
                      disabled={currentPage === startPage + index}
                    >
                      {startPage + index}
                    </a>
                  </li>
                ))}
                {currentPage !== NoOfPages && NoOfPages > 1 && (
                  <li className="page-item">
                    <a
                      className={`page-link ${
                        currentPage === NoOfPages ? "disabled" : ""
                      }`}
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
          </div>
        
        )}
      </div>
    </>
  );
}
