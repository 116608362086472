import React from "react";
import header from "../assets/header/caver Pages.jpg";
export default function NotFound() {
  return (
    <>
        <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-12 mt-5 pt-5 pe-2" style={{ paddingRight: "0" }}>
            <img src={header}  className="headerBg"  />
          </div>
        </div>
      </div>
      <div className="my-5 py-5 text-danger ms-md-4 d-flex justify-content-center align-items-center">
        <h1 className="my-5 me-2">Not Found Page</h1>
        <i className="fa-solid fa-bug fs-1"></i>
      </div>
    </>
  );
}
