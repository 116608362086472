import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../styles/featuredProduct/featuredProduct.css";
import "swiper/css"; // Import Swiper CSS
import bannerImg from "../../assets/banner/banner.jpg";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser, faHeart } from "@fortawesome/free-regular-svg-icons";
import axios from "../../axios/axios";
import { Link } from "react-router-dom";
import Spinner from "../Auth/Spinner";
import { toast } from "react-toastify";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { useWishlist } from "../../WishlistContext";
import { useCart } from "../../CartContext";

const RelatedProducts = ({ id }) => {
  const { updateWishlistCount } = useWishlist();
  const { updateCartCount } = useCart();
  const language = sessionStorage.getItem("lang") || "en";

  // const pp = ["1", "2", "3", "4", "5", "6"]
  // const swiperRef = useRef(null)
  const [products, setProducts] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [activeVariant, setActiveVariant] = useState("");
  const [reload, setReload] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [loadingCart, setLoadingCart] = useState(false);
  const [wishlistStatus, setWishlistStatus] = useState({});

  // Update the local state upon adding or removing an item from the wishlist
  const updateWishlistStatus = (productId, isFav) => {
    setWishlistStatus((prevStatus) => ({
      ...prevStatus,
      [productId]: isFav,
    }));
  };

  const fetchProducts = () => {
    setLoading2(true);
    axios
      .get(`/products/relatedProducts/${id}`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        // console.log(res.data);
        setProducts(res.data?.relatedProduct);
      })
      .catch((err) => console.error(err.response?.data?.message))
      .finally((_) => setLoading2(false));
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  const handleAddtoCart = (check, productId, variantId) => {
    if (!check) {
      axios
        .post(
          "users/addToCart",
          {
            productId: productId,
            variantId: variantId,
            quantity: quantity,
          },
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) + 1
          );
          setQuantity(1);
          setReload(!reload);
          updateCartCount((previousCount) => previousCount + 1);

          fetchProducts();
          toast.success("product added to your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    } else {
      axios
        .delete("users/removeFromCart/" + productId + "/" + variantId, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) - 1
          );
          setReload(!reload);
          updateCartCount((previousCount) => previousCount - 1);

          fetchProducts();
          toast.success("product removed from your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    }
  };
  const addToWishlist = (productId, variantId) => {
    axios
      .post(
        "users/addToWishList",
        {
          productId: productId,
          variantId: variantId,
        },
        { headers: { Authorization: sessionStorage.getItem("token") } }
      )
      .then((response) => {
        sessionStorage.setItem(
          "wishList",
          parseInt(sessionStorage.getItem("wishList")) + 1
        );
        updateWishlistStatus(productId, true);
        setReload(!reload);
        updateWishlistCount((previousCount) => previousCount + 1);

        fetchProducts();
        toast.success("product added to your wishlist", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      });
  };

  const removeFromWishlist = (productId, variantId) => {
    axios
      .delete("users/removeFromWishList/" + productId + "/" + variantId, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((response) => {
        sessionStorage.setItem(
          "wishList",
          parseInt(sessionStorage.getItem("wishList")) - 1
        );
        updateWishlistStatus(productId, false);
        setReload(!reload);
        updateWishlistCount((previousCount) => previousCount - 1);

        fetchProducts();
        toast.success("product removed from your wishlist", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
      });
  };

  const handleAddtoWishList = (check, productId, variantId) => {
    if (check) {
      addToWishlist(productId, variantId);
    } else {
      removeFromWishlist(productId, variantId);
    }
  };
  return (
    <div className="container">
      <div className="swiper-container">
        <div
          className="swiper-wrapper row"
          style={{ justifyContent: "center" }}
        >
          <Swiper
            spaceBetween={5}
            slidesPerView={window.innerWidth <= 768 ? 1 : 4}
            // slidesPerView={4}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {!loading2 &&
              Array.isArray(products) &&
              products.length > 0 &&
              products.map((item, index) => (
                <SwiperSlide
                  key={index}
                  style={{ justifyContent: "center", display: "row" }}
                >
                  <div
                    className=""
                    style={{ marginLeft: "1rem", marginRight: "0px" }}
                  >
                    <div
                      className="item"
                      style={{
                        marginBottom: "4rem",
                        border: "1px solid #e23b26",
                        marginTop: "4rem",
                      }}
                    >
                      <div className="image">
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={1}
                          // onSlideChange={() => console.log("slide change")}
                          // onSwiper={(swiper) => console.log(swiper)}
                        >
                          {" "}
                          {item.images.map((image, index) => (
                            <SwiperSlide
                              key={index}
                              onClick={() => window.location.reload()}
                            >
                              <Link to={`/singleProduct/${item._id}`}>
                                <img
                                  src={image}
                                  alt={`Banner `}
                                  // style={{ height: "33vh" }}
                                />
                              </Link>
                            </SwiperSlide>
                          ))}{" "}
                        </Swiper>
                        {item.variants[0]?.stock > 0 &&
                        item.variants[0]?.isSale === true ? (
                          <div className="discountShop">
                            {item.variants[0]?.salePercentage}% <br /> off
                          </div>
                        ) : (
                          ""
                        )}

                        {/* // item.variants.map((variant, index) => */}
                        {item.variants[0].isAvailable === true ? (
                          <div
                            className="cartButtonProduct"
                            style={{ zIndex: "9999" }}
                            // key={index}
                          >
                            <button
                              className="cart"
                              onClick={() => {
                                if (sessionStorage.getItem("token")) {
                                  handleAddtoCart(
                                    item.variants[0].inCart,
                                    item._id,
                                    item.variants[0]._id
                                  );
                                } else {
                                  window.location.replace("/login");
                                }
                              }}
                            >
                              {loadingCart ? (
                                <Spinner />
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#fff",
                                      fontSize: "1.3rem",
                                    }}
                                    className="icon"
                                    icon={
                                      item.variants[0].inCart
                                        ? faTimesCircle
                                        : faBasketShopping
                                    } // Change icon based on cart status
                                    size="xl"
                                  />
                                 {language === "ar" ?  <span>
                                    {item.variants[0].inCart
                                      ? "إزالة من الحقيبة"
                                      : "أضف الى الحقيبة"}
                                  </span>:<span>
                                    {item.variants[0].inCart
                                      ? "REMOVE FROM BAG"
                                      : "ADD TO BAG"}
                                  </span>}
                                  {/* Change button text based on cart status */}
                                </>
                              )}
                            </button>
                            <button className="heart heart-container">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={item.variants[0].isFav}
                                onChange={(e) => {
                                  if (sessionStorage.getItem("token")) {
                                    const isChecked = e.target.checked;
                                    handleAddtoWishList(
                                      isChecked,
                                      item._id,
                                      item.variants[0]._id
                                    );
                                  } else {
                                    window.location.replace("/login");
                                  }
                                }}
                                id="Give-It-An-Id"
                              />
                              {item?.variants[0]?.isFav ||
                              wishlistStatus[item._id] ? (
                                <i className="fa-solid fa-heart"></i>
                              ) : (
                                <FontAwesomeIcon
                                  style={{ fontSize: "1.3rem" }}
                                  className="icon"
                                  icon={faHeart}
                                />
                              )}
                            </button>
                          </div>
                        ) : (
                          <div
                            className="cartButtonProduct"
                            style={{ justifyContent: "center" }}
                            key={index}
                          >
                            <button
                              className="cart"
                              style={{ cursor: "default" }}
                            >
                              <span>  {language === "ar" ?"إنتهى من المخزن":"OUT OF STOCK"}</span>
                            </button>
                          </div>
                        )}
                        {/* <div className="cartButtonProduct">
                          <button className="cart">
                            {" "}
                            <FontAwesomeIcon
                              style={{ color: "#fff", fontSize: "1.3rem" }}
                              className="icon"
                              icon={faBasketShopping}
                              size="xl"
                            />
                            <span> ADD TO BAG</span>
                          </button>
                          <button className="heart">
                            <FontAwesomeIcon
                              style={{ fontSize: "1.3rem" }}
                              className="icon"
                              icon={faHeart}
                            />{" "}
                          </button>
                        </div> */}
                      </div>
                      <Link
                        to={`/singleProduct/${item._id}`}
                        onClick={() => window.location.reload()}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        <div className="content p-3">
                          {/* <div className="subCategory">
                            {item.subCategory?.category?.name}
                          </div> */}
                          <div
                            className="title mb-3"
                            style={{ height: "4vh", fontFamily: "BrownMedium" }}
                          >
                            {language === "ar" ?item?.nameAr:item?.name}
                          </div>

                          <React.Fragment>
                            <div className="price">
                              {item.variants[0]?.stock === 0 ? (
                                <>
                                  <div className="discountShop">
                                  {language === "ar" ?"إنتهى من المخزن": "Out of stock"}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </React.Fragment>
                          <div className="price">
                            {" "}
                            <div className="price">
                              {item.variants[0]?.isSale === true ? (
                                <>
                                  {item.variants[0]?.price.toFixed(2)} {language === "ar" ?"ج م": "EGP"}
                                  <span
                                    className="priceShop"
                                    style={{ marginLeft: "8px" }}
                                  >
                                    {item.variants[0]?.price +
                                      item.variants[0]?.saleValue}{" "}
                                    EGP
                                  </span>
                                </>
                              ) : (
                                item.variants[0]?.price.toFixed(2) + language === "ar" ?"ج م": "EGP"
                              )}
                            </div>
                          </div>
                          {item.noOfRatings > 0 ? (
                            <div className="d-flex gap-2">
                              <Rating
                                name="read-only"
                                value={item?.rating}
                                readOnly
                                size="small"
                              />{" "}
                              <span className="rating">
                                ({item.noOfRatings} {language === "ar" ?"التقييمات":"ratings"})
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex gap-2">
                              <Rating
                                name="read-only"
                                value={item?.rating}
                                readOnly
                                size="small"
                              />{" "}
                              <span className="rating">
                                ({item.noOfRatings} {language === "ar" ?"التقييمات":"ratings"})
                              </span>
                            </div>
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>{" "}
                </SwiperSlide>
              ))}{" "}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RelatedProducts;
