import React,{useEffect} from 'react'
import BlogHeader from '../components/Header/BlogHeader'
import Blogs from '../components/Blogs/Blogs'

export default function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>

<BlogHeader/>
<Blogs/>
</>  )
}
