import { Navigate, Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import NavHeader from "./components/headers/navHeader";
import TopHeader from "./components/headers/TopHeader";
import NavBarHeader from "./components/headers/navBarItemsHeader";
import Home from "./pages/home.jsx";
import About from "./pages/About";
import Blog from "./pages/Blog.jsx";
import Tutorial from "./pages/Tutorial.jsx";
import SingleBlog from "./components/Blogs/SingleBlog.jsx";
import Login from "./pages/Login.jsx";
import Register from "./pages/Register.jsx";
import Contact from "./pages/Contact.jsx";
// import FaceInHole from "./pages/FaceinHole.jsx"
import Cart from "./pages/Cart.jsx";

import Footer from "./components/Footer/Footer.jsx";
import Shop from "./pages/Shop.jsx";
import SingleProduct from "./components/Shop/SingleProduct.jsx";
import Verify from "./components/Auth/Verify.jsx";
import Wishlist from "./pages/Wishlist";
import Profile from "./pages/Profile.jsx";
import Navbar from "./components/headers/Navbar.jsx";
import Forget from "./pages/Forget.jsx";
import Privacy from "./pages/Privacy.jsx";
import NotFound from "./pages/NotFound.jsx";
function App() {
  const [value, setValue] = useState("one");

  return (
    <>
      <TopHeader />
      {/* <NavHeader /> */}
      {/* <NavBarHeader value={value} setValue={setValue} /> */}
      <Navbar/>
      <Routes>
        {/* Login */}
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/tutorial" element={<Tutorial />} /> */}
        <Route path="/singleBlog/:id" element={<SingleBlog />} />
        {/* <Route path="/styles" element={<FaceInHole />} /> */}
        {/* <Route path="/faceinhole" element={<FaceInHole/>}/> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/singleProduct/:id" element={<SingleProduct />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forget/:token" element={<Forget />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <Footer />
    </>
  );
}

export default App;
