import React, { useState } from "react"
import axios from "../../axios/axios"
import Spinner from "../Auth/Spinner"
import { toast } from "react-toastify"
import element from '../../assets/PNG/ELEMNTS-02.png'

export default function ContactForm() {
  const language = sessionStorage.getItem("lang") || "en";

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [loading, setLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await axios.post("/contactUs/contact", {
        name:name,
        email: email,
        phone: phone,
        message: message,

      })

      toast.success(response.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
      })
      // console.log(response)
      setLoading(false)
      setEmail('')
      setName('')
      setPhone('')
      setMessage('')

    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
      })
      setLoading(false)
      console.error("Login failed:", error.message)
    }
  }
  return (
    <>
      <div className=" containerLogin">
          <div className="d-flex aling-items-center justify-content-between">
          <div className="headingContact">  {language === "ar"?"تواصل معنا":"Contact Us"}</div>
          <img src={element} className="d-" width={50} alt="" />


          </div>
            {/* <p className="contactLine">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. </p> */}
            <form className="formContact" onSubmit={handleSubmit}>
            <input
                placeholder={language === "ar"?"الاسم":"Name"}
                id="name"
                name="name"
                type="text"
                className="input"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                placeholder={language === "ar"?"البريد الالكتروني":"E-mail"}
                id="email"
                name="email"
                type="email"
                className="input"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
               <input
                placeholder={language === "ar"?"رقم التليفون":"Phone"}
                id="phone"
                name="phone"
                type="number"
                className="input"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <textarea
                placeholder={language === "ar"?"الموضوع":"Subject"}
                id="subject"
                name="subject"
                type="text"
                className="input"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
           <div style={{justifyContent:'end',display:'flex'}}>
              <button type="submit" className="custom-btn mt-3" >
                  <span>      {loading ? <Spinner /> :language === "ar"?"تأكيد":"Submit"}</span>
              </button></div>
              {/* <input value="Sign In"  className="login-button" /> */}
            </form>
           
        </div>
    </>
  )
}
