import { Rating } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../axios/axios";
export default function AddReview({ id }) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const language = sessionStorage.getItem("lang") || "en";

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send POST request to your API endpoint
      const response = await axios.post(
        `/reviews/${id}`,
        {
          rating,
          review,
        },
        {
          headers: { Authorization: sessionStorage.getItem("token") },
        }
      );
      toast.success("review sent successfully.", {
        hideProgressBar: true,
        autoClose: 3000,
      });
      // Handle success response
      // console.log("Review added successfully:", response.data);

      // Clear form fields
      setRating(0);
      setReview("");
    } catch (error) {
      toast.error(error.response?.data?.message, {
        hideProgressBar: true,
        autoClose: 3000,
      });
      // Handle error
      console.error("Error adding review:", error);
    }
  };
  return (
    <>
      <div className="row p-md-0 p-3" style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <div className="col">
          <p
            style={{
              fontWeight: "700",
              fontSize: "18px",
              fontFamily: "Holydayfree",
            }}
          >
           {language === "ar" ?"إضافة تقييمك":"Add your review"}  
          </p>
          <form
            className="form"
            style={{ padding: "0" }}
            onSubmit={handleSubmit}
          >
            <div
              className="d-flex gap-2"
              style={{
                marginTop: "1rem",
                alignItems: "center",
                fontFamily: "BrownMedium",
              }}
            >
           {language === "ar" ? "إضافة تقييمك": " Add your rate"}
              {/* Here you should implement your rating component */}
              {/* For example, you might use a library like Material-UI Rating */}
              <Rating
                name="read-only"
                size="medium"
                value={rating}
                onChange={(event, newValue) => setRating(newValue)}
              />
            </div>
            <textarea
              placeholder="Review"
              id="review"
              name="review"
              type="review"
              className="input"
              required=""
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
            <button type="submit" className="custom-btn mt-3">
              <span style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                {language === "ar" ?"تأكيد":  "Submit"}{" "}
              </span>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
