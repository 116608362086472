import React, { useContext, useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Thumbs } from "swiper";
import SwiperCore from "swiper/core";
import { useParams } from "react-router-dom";
import productImage from "../../assets/tips/caver Tips.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import feedback from "../../assets/feedback/profile-12 (1).jpeg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import AddReview from "./AddReview";
import Product from "../featuredProduct/Products";
import axios from "../../axios/axios";
import RelatedProducts from "./RelatedProducts";
import { isMobile } from "react-device-detect";
import Spinner from "../Auth/Spinner";
// import { color } from "html2canvas/dist/types/css/types/color";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs]);

export default function SingleProduct() {
  const language = sessionStorage.getItem("lang") || "en";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [quantity, setQuantity] = useState(1);
  const params = useParams();
  const { id } = params;
  const [addedToCart, setAddedToCart] = useState(false);
  const [addedToWishList, setAddedToWishList] = useState(false);
  const [product, setProduct] = useState();
  const [reviews, setReviews] = useState();
  const [activeVariant, setActiveVariant] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [loadingCart, setLoadingCart] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [variantStates, setVariantStates] = useState({});
  const [loadingIncrements, setLoadingIncrements] = useState(false);
  const [loadingDecrements, setLoadingDecrements] = useState(false);
  const [quantities, setQuantities] = useState({});

  const [reload, setReload] = useState(false);
  const handleAddtoWishList = (check) => {
    if (check) {
      axios
        .post(
          "users/addToWishList",
          {
            productId: product._id,
            variantId: activeVariant._id,
          },
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "wishList",
            parseInt(sessionStorage.getItem("wishList")) + 1
          );
          setReload(!reload);
        })
        .catch((error) => {});
    } else {
      axios
        .delete(
          "users/removeFromWishList/" + product._id + "/" + activeVariant._id,
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "wishList",
            parseInt(sessionStorage.getItem("wishList")) - 1
          );
          setReload(!reload);
        })
        .catch((error) => {});
    }
  };
  const handleAddtoCart = (check, quantity) => {
    if (check) {
      axios
        .post(
          "users/addToCart",
          {
            productId: product._id,
            variantId: activeVariant._id,
            quantity: quantity,
          },
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) + 1
          );
          setQuantity(1);
          setReload(!reload);
          toast.success("product added to your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    } else {
      axios
        .delete(
          "users/removeFromCart/" + product._id + "/" + activeVariant._id,
          { headers: { Authorization: sessionStorage.getItem("token") } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cart",
            parseInt(sessionStorage.getItem("cart")) - 1
          );
          setReload(!reload);
          toast.success("product removed from your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    }
  };
  const handleQuantityChange = (variantId, newQuantity) => {
    setVariantStates((prevState) => ({
      ...prevState,
      [variantId]: {
        ...prevState[variantId],
        quantity: newQuantity,
      },
    }));
  };

  const increment = (variantId) => {
    setLoadingIncrements(true);
    const currentQuantity = variantStates[variantId]?.quantity || 0;
    const stock =
      product.variants.find((variant) => variant._id === variantId)?.stock || 0;
    const data = {
      productId: id,
      variantId: variantId,
      quantity: currentQuantity + 1,
    };
    if (currentQuantity < stock) {
      axios
        .put("/users/updateCartProduct", data, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          handleQuantityChange(variantId, currentQuantity + 1);
          setQuantities({
            ...quantities,
            [variantId]: currentQuantity + 1,
          });
          setLoadingIncrements(false);
          // console.log("Update successful", response.data);
        })
        .catch((error) => {
          setLoadingIncrements(false);
          
          toast.error(error.response?.data?.message ==='jwt must be provided'&&"Login First", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
          console.error("Error updating cart", error);
        });
    } else {
      // console.log("Cannot increment beyond available stock");
    }
  };

  const decrement = (variantId) => {
    setLoadingDecrements(true);

    const currentQuantity = variantStates[variantId]?.quantity || 0;
    const stock =
      product.variants.find((variant) => variant._id === variantId)?.stock || 0;
    const data = {
      productId: id,
      variantId: variantId,
      quantity: currentQuantity - 1,
    };

    if (stock > 1) {
      axios
        .put("/users/updateCartProduct", data, {
          headers: { Authorization: sessionStorage.getItem("token") },
        })
        .then((response) => {
          handleQuantityChange(variantId, currentQuantity - 1);
          setLoadingDecrements(false);
          // console.log("Update successful", response.data);
        })
        .catch((error) => {
          setLoadingDecrements(false);
          console.error("Error updating cart", error);
        });
    } else {
      // console.log("Cannot decrement below 1");
    }
  };

  useEffect(() => {
    setLoading2(true);

    axios
      .get("products/all/" + id, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((response) => {
        setLoading2(false);
        setSelectedImage(response.data.product?.images[0]);
        // console.log(response.data.product);
        setProduct(response.data.product);
        setActiveVariant(response.data.product.variants[0]);
      })
      .catch((err) => {});
    axios
      .get("reviews/" + id + `?page=` + 1 + `&perPage=` + 2, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((response) => {
        // console.log(response);
        setReviews(response.data.reviews);
      })
      .catch((err) => {});
  }, [reload]);

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "10rem", marginBottom: "1rem" }}
        {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
      >
        {loading2 ? (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
              height: "50vh",
              alignItems: "center",
            }}
          >
            <div className="typing-indicator">
              <div className="typing-circle"></div>
              <div className="typing-circle"></div>
              <div className="typing-circle"></div>
              <div className="typing-shadow"></div>
              <div className="typing-shadow"></div>
              <div className="typing-shadow"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="row pb-0 mb-0">
              <p>
                {" "}
                {language === "ar"
                  ? "الرئيسية / المنتجات /" + product?.nameAr
                  : "Home / Product /" + product?.name}
              </p>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div
                  className="productSlider image-slider-container"
                  style={{ paddingRight: isMobile? "1rem":'3rem', paddingLeft: isMobile? "1rem":'3rem'}}
                >
                  <div className="col h-100">
                    <div className=" h-100 d-flex flex-column justify-content-center">
                      <p className="titleTips"></p>
                      <a
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <img
                          className=" mb-md-0  mb-3"
                          src={selectedImage}
                          style={{
                        
                            width: "100%",
                            border: "1px solid var(--main-color)",
                            borderRadius: "10px",
                          }}
                          alt={`Product Image preview`}
                        />
                      </a>
                    </div>
                  </div>
                  {product?.images.length > 1 ? (
                    <Swiper
                      className="mt-3"
                      modules={[Scrollbar]}
                      spaceBetween={10}
                      slidesPerView={4}
                      // navigation
                      // pagination={{ clickable: true }}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      thumbs={{ swiper: thumbsSwiper }}
                    >
                      {product?.images.map((image, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="col h-100 ">
                              <div className=" h-100 d-flex justify-content-center ">
                                <p className="titleTips"></p>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                  }}
                                  // className="cardslideimg"
                                  onClick={(_) => setSelectedImage(image)}
                                >
                                  <img
                                    className="cardslideimg"
                                    src={image}
                                    style={{
                                      marginBottom: "40px",
                                      height: "150px",
                                      width: "100%",
                                      border: "1px solid var(--main-color)",
                                      borderRadius: "10px",
                                    }}
                                    alt={`Product Image ${index}`}
                                  />
                                </a>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="row"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ display: "contents" }}>
                    <p
                      style={{ paddingLeft: "10px", fontFamily: "BrownMedium" }}
                    >
                      {language === "ar"
                        ? product?.subCategory?.nameAr
                        : product?.subCategory?.name}
                    </p>
                  </div>
                  <div className="heart-container me-md-0 me-3" title="Like">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={activeVariant.isFav}
                      onChange={(e) => {
                        if (sessionStorage.getItem("token")) {
                          setActiveVariant({
                            ...activeVariant,
                            isFav: !activeVariant.isFav,
                          });
                          handleAddtoWishList(e.target.checked);
                        } else {
                          window.location.replace("/login");
                        }
                      }}
                      id="Give-It-An-Id"
                    />
                    <div className="svg-container">
                      <svg
                        viewBox="0 0 24 24"
                        className="svg-outline"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                      </svg>
                      <svg
                        viewBox="0 0 24 24"
                        className="svg-filled"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                      </svg>
                      <svg
                        className="svg-celebrate"
                        width="100"
                        height="100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon points="10,10 20,20"></polygon>
                        <polygon points="10,50 20,50"></polygon>
                        <polygon points="20,80 30,70"></polygon>
                        <polygon points="90,10 80,20"></polygon>
                        <polygon points="90,50 80,50"></polygon>
                        <polygon points="80,80 70,70"></polygon>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #e23b26" }}
                >
                  <h4
                    style={{ fontSize: "35px", fontFamily: "BrownMedium" }}
                    className="mainP"
                  >
                    {language === "ar" ? product?.nameAr : product?.name}
                  </h4>
                  <br />
                  {/* {product && product.noOfRatings > 0 ? (
                    <div className="d-flex gap-2">
                      <Rating
                        name="read-only"
                        value={product?.noOfRatings}
                        readOnly
                        size="small"
                      />{" "}
                      <span className="rating">({product?.noOfRatings}/5)</span>
                    </div>
                  ) : (
                    ""
                  )}
                  <br /> */}
                  <div
                    className="price"
                    style={{
                      color: "rgb(226, 59, 38)",
                      fontSize: "30px",
                      fontWeight: "600",
                      fontFamily: "BrownMedium",
                    }}
                  >
                    {activeVariant.price}{" "}
                    <span style={{ fontSize: "18px" }}>
                      {language === "ar" ? "ج م" : "EGP"}
                    </span>
                    {activeVariant.isSale && (
                      <>
                        <span
                          className="priceShop "
                          style={{ marginLeft: "2%", marginRight: "2%" }}
                        >
                          {activeVariant.price + activeVariant.saleValue} EGP
                        </span>
                        {activeVariant.salePercentage > 0 && (
                          <span className="perc" style={{ fontSize: "18px" }}>
                            -{activeVariant.salePercentage}%
                          </span>
                        )}
                        {/* {activeVariant.saleValue > 0 && (
                          <span className="perc">
                            -{activeVariant.saleValue}EGP
                          </span>
                        )} */}
                      </>
                    )}
                  </div>
                  <div
                    className="row"
                    // style={{ justifyContent: "space-between", width: "38%" }}
                  >
                    {/* <div
                      className="col"
                      style={{ display: "flex", alignItems: "center" }}
                    > */}
                    <div className="col-md-3">
                      {product &&
                        product.variants.map((variant, index) => (
                          <div key={index}>
                            {variant.color && index === 0 && (
                              <p
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0",
                                  marginRight: "0.5rem",
                                  fontSize: "17px",
                                  marginTop: "0.7rem",
                                  marginBottom: "0.7rem",
                                }}
                              >
                                {language === "ar"
                                  ? "اختر لون"
                                  : "Select color:"}
                              </p>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="col-md-9" style={{ display: "flex" }}>
                      {product &&
                        product.variants.map((variant, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                paddingRight: "0",
                                paddingLeft: "0",
                                marginTop: "0.7rem",
                              }}
                            >
                              {variant.color ? (
                                <span onClick={() => setActiveVariant(variant)}>
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: `${variant.color.name}`,
                                      border: ` 1px solid gray`,
                                      padding: "5px",

                                      marginLeft: "0.7rem",
                                    }}
                                  ></div>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>{" "}
                  <div className="row">
                    {isMobile ? (
                      <div className=" d-flex align-items-center">
                        <div className="col-md-3">
                          {product &&
                            product.variants?.some(
                              (variant) => variant.volume
                            ) && (
                              <p
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0",
                                  marginRight: "0.5rem",
                                  fontSize: "17px",
                                  marginTop: "0.7rem",
                                  fontFamily: "BrownMedium",
                                }}
                              >
                                {language === "ar"
                                  ? "الحجم (مل)"
                                  : "Size (ML):"}
                              </p>
                            )}
                        </div>
                        <div className="col-md-9" style={{ display: "flex" }}>
                          {product &&
                            product.variants.map((variant, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    marginTop: "0.7rem",
                                  }}
                                >
                                  {variant.volume ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {/* {product &&
                                    product.variants.map((variant, index) => (
                                      <div key={index}>
                                        {variant.volume && index === 0 && (
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "0",
                                            }}
                                          >
                                            Size:
                                          </p>
                                        )}
                                      </div>
                                    ))} */}
                                      <div
                                        className="m-1 p-2"
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                          border: "1px solid #d0d0d0",
                                          borderRadius: "100px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            setActiveVariant(variant)
                                          }
                                          key={index}
                                          style={{
                                            cursor: "pointer",
                                            fontWeight:
                                              activeVariant._id == variant._id
                                                ? "400"
                                                : "300",
                                            color:
                                              activeVariant._id ==
                                                variant._id && "#e23b26",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {variant.volume}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="col-md-3">
                          {product &&
                            product.variants?.some(
                              (variant) => variant.volume
                            ) && (
                              <p
                                style={{
                                  fontWeight: "600",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0",
                                  marginRight: "0.5rem",
                                  fontSize: "17px",
                                  marginTop: "0.7rem",
                                  fontFamily: "BrownMedium",
                                }}
                              >
                                {language === "ar"
                                  ? "الحجم (مل)"
                                  : "Size (ML):"}
                              </p>
                            )}
                        </div>
                        <div className="col-md-9" style={{ display: "flex" }}>
                          {product &&
                            product.variants.map((variant, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    marginTop: "0.7rem",
                                  }}
                                >
                                  {variant.volume ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {/* {product &&
                                    product.variants.map((variant, index) => (
                                      <div key={index}>
                                        {variant.volume && index === 0 && (
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "0",
                                            }}
                                          >
                                            Size:
                                          </p>
                                        )}
                                      </div>
                                    ))} */}
                                      <div
                                        className="m-1 p-2"
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                          border: "1px solid #d0d0d0",
                                          borderRadius: "100px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            setActiveVariant(variant)
                                          }
                                          key={index}
                                          style={{
                                            cursor: "pointer",
                                            fontWeight:
                                              activeVariant._id == variant._id
                                                ? "400"
                                                : "300",
                                            color:
                                              activeVariant._id ==
                                                variant._id && "#e23b26",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {variant.volume}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {/* </div> */}
                    {/* <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      {" "}
                    </div> */}
                  </div>
                  {product?.hairType && (
                    <div className="row">
                      <div
                        className="col"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <p
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            margin: "0",
                            marginRight: "0.5rem",
                            fontSize: "17px",
                            marginTop: "0.7rem",
                            marginBottom: "0.7rem",
                            fontFamily: "BrownMedium",
                          }}
                        >
                          {language === "ar" ? "نوع الشعر" : "Hair Type:"}
                        </p>

                        <div
                          className="col"
                          style={{
                            paddingRight: "0",
                            paddingLeft: "0",
                            marginTop: "0.7rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "0.7rem",
                            }}
                          >
                            <div
                              className="m-1 p-2"
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                                border: "1px solid #d0d0d0",
                                borderRadius: "100px",
                                alignItems: "center",
                                color: "var(--main-color)",
                              }}
                            >
                              {product?.hairType}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* {console.log(activeVariant)} */}
                <div className="row">
                  {activeVariant.isAvailable === true ? (
                    <>
                      <p
                        style={{
                          marginTop: "2rem",
                          fontWeight: "600",
                          fontFamily: "BrownMedium",
                        }}
                      >
                        {language === "ar" ? "كمية" : "QUANTITY"}
                      </p>
                      <div className="row d-flex ">
                        <div className="col-md-3">
                          <div
                            className="quantity"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="spinner-container"
                              style={{
                                position: "absolute",
                                display:
                                  loadingDecrements || loadingIncrements
                                    ? "flex"
                                    : "none",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background to indicate loading
                                zIndex: 999, // Ensure the spinner is above other elements
                              }}
                            >
                              <Spinner />
                            </div>
                            <div
                              className="dec qtybutton"
                              onClick={() => decrement(activeVariant._id)}
                            >
                              -
                            </div>
                            {/* {console.log(variantStates)} */}
                            <input
                              className="quantityCart"
                              type="text"
                              value={
                                quantities[activeVariant._id] ||
                                activeVariant.quantity
                              }
                              readOnly
                            />
                            {/* {console.log(activeVariant)} */}
                            {/* <input
                              className="quantityCart"
                              type="text"
                              value={
                                variantStates[activeVariant._id]?.quantity || 1
                              }
                              readOnly
                            /> */}
                            <div
                              className="inc qtybutton"
                              onClick={() => increment(activeVariant._id)}
                            >
                              +
                            </div>
                          </div>
                        </div>

                        {/* ) : (
                      ""
                    )
                  )} */}
                        <div className="col-md-9 text-center">
                          <div className="">
                            <div className="cartButton ">
                              {activeVariant.isAvailable === true ? (
                                <button
                                  className="cart mt-md-0 mt-3 addToCartBtn"
                                  onClick={() => {
                                    if (sessionStorage.getItem("token")) {
                                      const currentQuantity =
                                        variantStates[activeVariant._id]
                                          ?.quantity || 1;
                                      const stock = activeVariant.stock;

                                      // Check if adding the current quantity will exceed the stock
                                      if (currentQuantity > stock) {
                                        // console.log(
                                        //   "Cannot add more than available stock"
                                        // );
                                        return;
                                      }

                                      // Toggle inCart status
                                      const updatedVariant = {
                                        ...activeVariant,
                                        inCart: !activeVariant.inCart,
                                      };
                                      setActiveVariant(updatedVariant);

                                      // Call handleAddtoCart with the new quantity
                                      handleAddtoCart(
                                        !activeVariant.inCart,
                                        currentQuantity
                                      );
                                    } else {
                                      window.location.replace("/login");
                                    }
                                  }}
                                >
                               {language === "ar"?   activeVariant.inCart
                                    ? "إزالة من الحقيبة"
                                    : "أضف الى الحقيبة":activeVariant.inCart
                                    ? "REMOVE FROM BAG"
                                    : "ADD TO BAG"}
                                </button>
                              ) : (
                                <div
                                  className="cartButtonProduct"
                                  style={{ marginTop: "1rem" }}
                                >
                                  <button
                                    className="cart"
                                    style={{ cursor: "default" }}
                                  >
                                    <span>
                                      {" "}
                                      {language === "ar"
                                        ? "غير متوفر"
                                        : "OUT OF STOCK"}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className="cartButtonProduct"
                      style={{ marginTop: "1rem" }}
                    >
                      <button className="cart" style={{ cursor: "default" }}>
                        <span>
                          {language === "ar" ? "غير متوفر" : "OUT OF STOCK"}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
                <div className="row  justify-content-center">
                  {activeVariant.isAvailable === true ? (
                    <div className="custom-btn m-3 text-center">
                      <Link
                        to={"/cart"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <span style={{ textAlign: "center" }}>
                          {language === "ar" ? "اشتري الآن" : "Buy Now"}
                        </span>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {language === "ar"? <p style={{ marginTop: "2rem", fontFamily: "BrownMedium" }}>
                  {" "}
                  {product && product.descriptionAr
                    ? product.descriptionAr.split(/\r?\n/).map((part, index) => (
                        <span key={index}>
                          {part}
                          <br />
                        </span>
                      ))
                    : ""}
                </p>: <p style={{ marginTop: "2rem", fontFamily: "BrownMedium" }}>
                  {" "}
                  {product && product.description
                    ? product.description.split(/\r?\n/).map((part, index) => (
                        <span key={index}>
                          {part}
                          <br />
                        </span>
                      ))
                    : ""}
                </p>}
              </div>
            </div>
            <div className="row" style={{ marginTop: "3rem" }}>
              <div className="col-md-4">
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "24px",
                    fontFamily: "Holydayfree ",
                  }}
                >
                  {language === "ar" ? "تقييم" : "Reviews"}
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "60px",
                    fontFamily: "BrownMedium",
                  }}
                >
                  {product?.rating}/5
                </p>
                <div
                  className="d-flex gap-2"
                  style={{ flexDirection: "column" }}
                >
                  <Rating
                    name="read-only"
                    value={product?.rating}
                    readOnly
                    size="small"
                  />{" "}
                  <span
                    className="rating"
                    style={{ fontSize: "18px", fontFamily: "BrownMedium" }}
                  >
                    {product?.noOfRatings + " "}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        fontFamily: "BrownMedium",
                      }}
                    >
                      {language === "ar" ? "تقييم" : "Reviews"}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-8">
                {reviews?.map((review, index) => {
                  return (
                    <div className="cardFeedback" key={index}>
                      <div className="headerFeedback">
                        <img
                          className="imageFeedback"
                          src={review?.user?.image}
                        />

                        <div>
                          <p className="nameFeedback">
                            {review?.user?.firstName +
                              " " +
                              review?.user?.lastName}
                          </p>
                          <Rating
                            name="read-only"
                            size="medium"
                            readOnly
                            value={review?.rating}
                          />
                        </div>
                      </div>

                      <p className="messageFeedback">{review?.review}</p>
                    </div>
                  );
                })}

                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <button className="custom-btn">
                    <span>VIEW MORE</span>
                  </button>
                </div> */}
              </div>
            </div>{" "}
            <AddReview id={id && id} />
            <RelatedProducts id={id && id} />
          </>
        )}
      </div>
    </>
  );
}
